import React, {
  useContext,
  useState,
  Fragment,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  FaRegHandshake,
  FaUser,
  FaRegClock,
  FaRegCalendarAlt,
  FaPencilAlt,
} from 'react-icons/fa';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import TimeField from '../../../../Common/Form/TimeField/TimeField';
import FullscreenDialog from '../../../../Dialogs/FullScreenDialog';
import DataPair from '../../../../Common/DataPair';
import Panel from '../../../../Common/Panel';
import SelectField from '../../../../Common/Form/SelectField/SelectField';
import {
  FlexFlow, PageText, PageSection, PageSectionTitle,
} from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';
import NumberField from '../../../../Common/Form/NumberField';
import DateField from '../../../../Common/Form/DateField/DateField';

import { EventRequirementsContext } from '../../context';
import MeetingRoomsModal from './MeetingRoomsModal';
import { RoomTypeOption, GroupizeIcon } from '../../styles';
import { i18n, i18nARAttr } from '../../../../../src/custom/Internationalization';

const roomTypes = [
  { value: 'boardroom', label: i18n.t('ebids.setup.boardroom') },
  { value: 'classroom', label: i18n.t('ebids.setup.classroom') },
  { value: 'crescent-rounds', label: i18n.t('ebids.setup.crescent-rounds') },
  { value: 'hollow-square', label: i18n.t('ebids.setup.hollow-square') },
  { value: 'rounds', label: i18n.t('ebids.setup.rounds') },
  { value: 'theatre-style', label: i18n.t('ebids.setup.theatre-style') },
  { value: 'u-shape', label: i18n.t('ebids.setup.u-shape') },
  { value: 'other', label: i18n.t('ebids.setup.other') },
];

const roomOptions = roomTypes.map(({ value, label }) => ({
  value,
  label: (
    <RoomTypeOption>
      <img
        src={`https://groupizereg-production.s3.amazonaws.com/images/meeting_types/style-diagram-${value}.jpg`}
        alt={i18n.t(`ebids.setup.${value}`)}
      />
      {label}
    </RoomTypeOption>
  ),
}));

function MeetingRoomForm({ id, isReadOnly }) {
  const [isDetailsModalOpen, toggleDetailsModal] = useState(false);

  const dataContext = useContext(EventRequirementsContext);
  const {
    formData: {
      setup,
      attendeesNum,
      meetingEndDate,
      meetingEndTime,
      meetingStartDate,
      meetingStartTime,
      meetingRoomNights,
      maxMeetingAttendees,
      isMeetingRoomsCustomized,
    },
    setField,
    formErrors,
  } = dataContext;

  const isCustomized = isMeetingRoomsCustomized;
  const roomNights = meetingRoomNights || [];

  const hasErrorMessage = (name) => {
    const messages = [];
    formErrors.forEach((e) => {
      if (e.path === name) {
        messages.push(e.message);
      }
    });
    return messages;
  };

  useEffect(() => {
    const currentDay = moment(meetingStartDate);
    if (currentDay.isAfter(meetingEndDate) || !moment(meetingEndDate).isValid()) {
      currentDay.add(1, 'd');
      setField('meetingEndDate', currentDay.format('YYYY-MM-DD'));
    }
  }, [meetingStartDate]);

  return (
    <PageSection id={id}>
      <PageSectionTitle>
        <FaRegHandshake />
        {i18n.t('event_request_form.headings.meeting_room_requirements')}
      </PageSectionTitle>

      {isCustomized && roomNights.map(({ date, rooms }) => (
        <Fragment key={date}>
          <PageSectionTitle>
            {moment(date).format('dddd, MMMM DD, YYYY')}
          </PageSectionTitle>

          {rooms.map(({
            attendeesNum,
            startTime,
            roomName,
            endTime,
            setup,
            type,
          }) => {
            const option = roomOptions.find((ro) => ro.value === setup);
            return (
              <Panel key={roomName}>
                <PageText blueTitle>
                  {roomName}
                </PageText>

                <FlexFlow splitSpace="2em">
                  <DataPair
                    title={`${i18nARAttr('meeting_room_day.start_time')}:`}
                    value={(
                      <FlexFlow nowrap splitSpace="0.5em">
                        <FaRegClock />
                        <span>{moment(startTime, 'HH:mm A').format(i18n.t('time.formats.short_time_js'))}</span>
                      </FlexFlow>
                    )}
                  />

                  <DataPair
                    title={`${i18nARAttr('meeting_room_day.end_time')}:`}
                    value={(
                      <FlexFlow nowrap splitSpace="0.5em">
                        <FaRegClock />
                        <span>{moment(endTime, 'HH:mm A').format(i18n.t('time.formats.short_time_js'))}</span>
                      </FlexFlow>
                    )}
                  />

                  <DataPair
                    title={`${i18nARAttr('meeting_room_day.number_of_attendees')}:`}
                    value={(
                      <FlexFlow nowrap splitSpace="0.5em">
                        <FaUser />
                        <span>{attendeesNum}</span>
                      </FlexFlow>
                    )}
                  />

                  <DataPair
                    title={`${i18nARAttr('meeting_room_day.room_setup')}:`}
                    value={option ? option.label : '--'}
                  />

                  <DataPair
                    title={`${i18nARAttr('meeting_room_day.meeting_type')}:`}
                    value={type}
                  />
                </FlexFlow>
              </Panel>
            );
          })}
        </Fragment>
      ))}

      {!isCustomized && (
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <FlexFlow column splitSpace="1em">
              <DateField
                isDisabled={isReadOnly}
                variant="inline"
                noMargin
                id="event_start_date"
                label={i18nARAttr('event.start_date')}
                value={meetingStartDate}
                onDateSelected={(v) => setField('meetingStartDate', v)}
                helpertext={hasErrorMessage('meetingStartDate').length > 0 ? hasErrorMessage('meetingStartDate').join(', ') : ''}
                error={hasErrorMessage('meetingStartDate').length > 0}
                minheight="40px"
                minDate={moment().format()}
                startAdornment={<FaRegCalendarAlt />}
              />
              <TimeField
                isDisabled={isReadOnly}
                variant="inline"
                nomargin
                id="meetingStartTime"
                label={i18nARAttr('meeting_room_day.start_time')}
                defaultValue={meetingStartTime}
                onChanged={(value) => setField('meetingStartTime', value)}
                minheight="40px"
                startAdornment={<FaRegClock />}
              />
              <NumberField
                disabled={isReadOnly}
                variant="inline"
                nomargin
                id="attendees_num"
                label={i18nARAttr('meeting_room_day.number_of_attendees')}
                defaultNum={attendeesNum}
                min={0}
                max={maxMeetingAttendees || null}
                onChanged={(value) => setField('attendeesNum', value)}
                minheight="40px"
                startAdornment={<FaUser />}
              />
            </FlexFlow>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <FlexFlow column splitSpace="1em">
              <DateField
                isDisabled={isReadOnly}
                variant="inline"
                noMargin
                id="event_end_date"
                label={i18nARAttr('event.end_date')}
                minDate={meetingStartDate || moment().format()}
                value={meetingEndDate}
                onDateSelected={(v) => setField('meetingEndDate', v)}
                helpertext={hasErrorMessage('meetingEndDate').length > 0 ? hasErrorMessage('meetingEndDate').join(', ') : ''}
                error={hasErrorMessage('meetingEndDate').length > 0}
                minheight="40px"
                startAdornment={<FaRegCalendarAlt />}
              />
              <TimeField
                isDisabled={isReadOnly}
                variant="inline"
                nomargin
                id="meetingEndTime"
                label={i18nARAttr('meeting_room_day.end_time')}
                defaultValue={meetingEndTime}
                onChanged={(value) => setField('meetingEndTime', value)}
                minheight="40px"
                startAdornment={<FaRegClock />}
              />
              <SelectField
                disabled={isReadOnly}
                variant="inline"
                id="event_setup"
                name="event_setup"
                label={i18nARAttr('meeting_room_day.room_setup')}
                defaultValue="Default"
                value={setup || ''}
                items={roomOptions}
                minheight="40px"
                onChange={(val) => setField('setup', val)}
              />
            </FlexFlow>
          </Grid>
        </Grid>
      )}

      {!isReadOnly && (
        <FlexFlow justify="flex-end">
          <GroupizeBtn onClick={() => toggleDetailsModal(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <GroupizeIcon wrapped>
                <FaPencilAlt />
              </GroupizeIcon>
              <span>{isCustomized ? i18n.t('actions.edit') : i18n.t('actions.customize')}</span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>
      )}

      <FullscreenDialog
        open={isDetailsModalOpen}
        handleClose={() => toggleDetailsModal(false)}
      >
        <MeetingRoomsModal
          handleClose={() => toggleDetailsModal(false)}
        />
      </FullscreenDialog>
    </PageSection>
  );
}

MeetingRoomForm.defaultProps = {
  id: '',
  isReadOnly: false,
};

MeetingRoomForm.propTypes = {
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

export default MeetingRoomForm;
