import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import {
  IoIosBed,
} from 'react-icons/io';
import {
  FaRegStar,
  FaConciergeBell,
} from 'react-icons/fa';
import * as yup from 'yup';
import NumberField from '../../../../Common/Form/NumberField';

const schema = yup.object().shape({
  maxSleepingRooms: yup
    .number()
    .nullable(),
  roomSingle: yup
    .number()
    .test('max', 'Total should not exceed total sleeping rooms', function (value = 0) {
      const { roomDouble, roomSuite, maxSleepingRooms } = this.parent;
      return value <= maxSleepingRooms - roomDouble - roomSuite;
    }),
  roomDouble: yup
    .number()
    .test('max', 'Total should not exceed total sleeping rooms', function (value = 0) {
      const { roomSingle, roomSuite, maxSleepingRooms } = this.parent;
      return value <= maxSleepingRooms - roomSingle - roomSuite;
    }),
  roomSuite: yup
    .number()
    .test('max', 'Total should not exceed total sleeping rooms', function (value = 0) {
      const { roomDouble, roomSingle, maxSleepingRooms } = this.parent;
      return value <= maxSleepingRooms - roomDouble - roomSingle;
    }),
});

function RoomNightForm({
  currentNight,
  roomSingle,
  roomDouble,
  roomSuite,
  setSleepingRoomNight,
  maxRooms,
}) {
  const [totalRooms, setTotalRooms] = useState(0);
  const [validationError, setValidationError] = useState([]);

  const calculateRooms = (single, double, suites) => {
    setTotalRooms(+single + +double + +suites);
  };

  const handleFormValidation = (formFields) => {
    schema
      .validate(formFields, { abortEarly: false })
      .then(
        () => {
          setValidationError([]);
        },
        (response) => {
          setValidationError(response.inner);
        },
      );
  };

  useEffect(() => {
    calculateRooms(roomSingle, roomDouble, roomSuite);
    handleFormValidation({
      roomSingle: +roomSingle,
      roomDouble: +roomDouble,
      roomSuite: +roomSuite,
      maxSleepingRooms: maxRooms,
    });
  }, [roomSingle, roomDouble, roomSuite]);

  const setsleepingRoomsData = (name, value) => {
    const newSRData = {
      roomSingle,
      roomDouble,
      roomSuite,
    };
    newSRData[name] = value;
    setSleepingRoomNight(newSRData, currentNight);
  };

  const hasErrorMessage = (name) => {
    const messages = [];
    validationError.forEach((e) => {
      if (e.path === name) {
        messages.push(e.message);
      }
    });
    return messages;
  };

  return (
    <Grid container spacing={3} justify="space-between">
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <NumberField
          nomargin
          variant="simple"
          min={0}
          id="room_single"
          label="Single"
          defaultNum={roomSingle}
          onChanged={(value) => setsleepingRoomsData('roomSingle', +value)}
          minheight="50px"
          startAdornment={<IoIosBed />}
          error={hasErrorMessage('roomSingle').length > 0}
          helperText={hasErrorMessage('roomSingle').length > 0 ? hasErrorMessage('roomSingle').join(', ') : ''}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <NumberField
          nomargin
          variant="simple"
          min={0}
          id="room_double"
          label="Double"
          defaultNum={roomDouble}
          onChanged={(value) => setsleepingRoomsData('roomDouble', +value)}
          minheight="50px"
          startAdornment={(
            <>
              <IoIosBed />
              <IoIosBed />
            </>
          )}
          error={hasErrorMessage('roomDouble').length > 0}
          helperText={hasErrorMessage('roomDouble').length > 0 ? hasErrorMessage('roomDouble').join(', ') : ''}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <NumberField
          nomargin
          variant="simple"
          min={0}
          id="room_suite"
          label="Suite"
          defaultNum={roomSuite}
          onChanged={(value) => setsleepingRoomsData('roomSuite', +value)}
          minheight="50px"
          startAdornment={<FaRegStar />}
          error={hasErrorMessage('roomSingle').length > 0}
          helperText={hasErrorMessage('roomSingle').length > 0 ? hasErrorMessage('roomSingle').join(', ') : ''}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <NumberField
          nomargin
          disabled
          variant="simple"
          min={0}
          id="room_Total"
          label="Total"
          defaultNum={totalRooms}
          minheight="50px"
          startAdornment={<FaConciergeBell />}
        />
      </Grid>
    </Grid>
  );
}

RoomNightForm.defaultProps = {
  maxRooms: 99,
  roomSingle: 0,
  roomDouble: 0,
  roomSuite: 0,
};

RoomNightForm.propTypes = {
  maxRooms: PropTypes.number,
  roomSingle: PropTypes.number,
  roomDouble: PropTypes.number,
  roomSuite: PropTypes.number,
  currentNight: PropTypes.string.isRequired,
  setSleepingRoomNight: PropTypes.func.isRequired,
};

export default RoomNightForm;
