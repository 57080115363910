import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FaUser,
  FaRegClock,
  FaDollarSign,
} from 'react-icons/fa';
import { FiTrash2, FiCopy } from 'react-icons/fi';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TimeField from '../../../../Common/Form/TimeField/TimeField';
import TextField from '../../../../Common/Form/TextField';
import Panel from '../../../../Common/Panel';
import SelectField from '../../../../Common/Form/SelectField/SelectField';
import ConfirmationDialog from '../../../../Dialogs/ConfirmationDialog';
import { FlexFlow } from '../../../../Common/helpers/DisplayHelpers';
import NumberField from '../../../../Common/Form/NumberField';
import { i18n, i18nARAttr } from '../../../../../src/custom/Internationalization';

// TODO: use fbOptions provided by controller instead
// This isnt being translated to avoid data inconsistency.
const fbTypes = [
  'breakfast',
  'morning_break',
  'lunch',
  'afternoon_break',
  'cocktail_reception',
  'dinner',
  'all_day_beverages',
];

function FnBTypeForm({
  fnbData,
  hasActionBtns,
  currentNight,
  removeFnBType,
  duplicateFnBType,
  setFnBTypeData,
  maxMeetingAttendees,
}) {
  const setFnBData = (name, value) => {
    const newData = {};
    newData[name] = value;
    const newMRData = { ...fnbData, ...newData };
    setFnBTypeData(newMRData, currentNight);
  };

  const [confirmationModalData, setConfirmationModalData] = useState({
    open: false,
    title: i18n.t('actions.delete'),
    maxWidth: 'sm',
    showActionBtns: true,
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.delete'),
    handleCancel: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
    handleConfirm: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
  });

  const showConfirmationModal = ({ onConfirm }) => {
    setConfirmationModalData({
      ...confirmationModalData,
      handleConfirm: onConfirm,
      open: true,
    });
  };

  return (
    <Panel>
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <SelectField
            variant="simple"
            id="event_setup"
            name="event_setup"
            label={i18nARAttr('food_requirement.food_type')}
            minheight="50px"
            value={fnbData.type}
            items={fbTypes.map((rt) => ({ label: i18n.t(`event_request_form.fb_options.${rt}`), value: rt }))}
            onChange={(val) => setFnBData('type', val)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={2}>
          <TextField
            disabled
            placeholder={i18n.t('event_request_form.messages.on_request')}
            nomargin
            variant="simple"
            id="attendees_num"
            label={i18nARAttr('food_requirement.price')}
            minheight="50px"
            defaultNum={fnbData.price}
            onChange={(value) => setFnBData('price', value)}
            startAdornment={<FaDollarSign />}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={2}>
          <NumberField
            nomargin
            variant="simple"
            id="attendees_num"
            label={i18nARAttr('meeting_room_day.number_of_attendees')}
            min={0}
            max={maxMeetingAttendees || null}
            defaultNum={fnbData.attendeesNum}
            onChanged={(value) => setFnBData('attendeesNum', +value)}
            minheight="50px"
            startAdornment={<FaUser />}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={2}>
          <TimeField
            nomargin
            variant="simple"
            id="startTime"
            label={i18nARAttr('food_requirement.start_time')}
            minheight="50px"
            defaultValue={fnbData.startTime}
            onChanged={(value) => setFnBData('startTime', value)}
            startAdornment={<FaRegClock />}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={2}
        >
          <TimeField
            nomargin
            variant="simple"
            id="endTime"
            label={i18nARAttr('food_requirement.end_time')}
            minheight="50px"
            defaultValue={fnbData.endTime}
            onChanged={(value) => setFnBData('endTime', value)}
            startAdornment={<FaRegClock />}
          />
        </Grid>

        {hasActionBtns && (
          <Grid
            justify="center"
            alignItems="center"
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={1}
          >
            <FlexFlow nowrap splitSpace="0.5em">
              <IconButton onClick={() => duplicateFnBType(fnbData.id, currentNight)}>
                <FiCopy />
              </IconButton>

              <IconButton
                onClick={() => showConfirmationModal({
                  onConfirm: () => removeFnBType(fnbData.id, currentNight),
                })}
              >
                <FiTrash2 />
              </IconButton>
            </FlexFlow>
          </Grid>
        )}
      </Grid>
      <ConfirmationDialog
        open={confirmationModalData.open}
        title={confirmationModalData.title}
        maxWidth={confirmationModalData.maxWidth}
        showActionBtns={confirmationModalData.showActionBtns}
        cancelBtnCopy={confirmationModalData.cancelBtnCopy}
        confirmBtnCopy={confirmationModalData.confirmBtnCopy}
        handleCancel={confirmationModalData.handleCancel}
        handleConfirm={confirmationModalData.handleConfirm}
      >
        {i18n.t('form_tips.remove_confirmation')}
      </ConfirmationDialog>
    </Panel>
  );
}

FnBTypeForm.defaultProps = {
  hasActionBtns: false,
  maxMeetingAttendees: null,
  duplicateFnBType: () => { },
  removeFnBType: () => { },
};

FnBTypeForm.propTypes = {
  fnbData: PropTypes.shape().isRequired,
  hasActionBtns: PropTypes.bool,
  currentNight: PropTypes.string.isRequired,
  removeFnBType: PropTypes.func,
  duplicateFnBType: PropTypes.func,
  setFnBTypeData: PropTypes.func.isRequired,
  maxMeetingAttendees: PropTypes.number,
};

export default FnBTypeForm;
