import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import StaticDateField from '../../../Common/Form/DateField/StaticDateField';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { FlexFlow } from '../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../src/custom/Internationalization';

function AddDateModal({
  isOpen,
  onCancel,
  onConfirm,
  endDate,
}) {
  const [newDate, setNewDate] = useState(moment(endDate).format('YYYY-MM-DD'));

  useEffect(() => {
    setNewDate(moment(endDate).format('YYYY-MM-DD'));
  }, [endDate]);

  const handleConfirm = () => {
    onConfirm(newDate);
  };

  const handleCancel = () => {
    onCancel();
  };

  const addDateModal = {
    open: isOpen,
    title: i18n.t('event_request_form.actions.add_date'),
    showActionBtns: true,
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.add'),
    maxWidth: 'xs',
    handleCancel,
    handleConfirm,
  };

  return (
    <ConfirmationDialog {...addDateModal}>
      <FlexFlow column align="center">
        <StaticDateField
          value={newDate}
          onDateSelected={(v) => setNewDate(v)}
        />
      </FlexFlow>
    </ConfirmationDialog>
  );
}

AddDateModal.defaultProps = {
  isOpen: false,
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
};

AddDateModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default AddDateModal;
