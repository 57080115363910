import styled, { css } from 'styled-components';
import { grey } from '@mui/material/colors';
import colors from '../../Common/constants/colors';

export const RoomNightWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid ${grey[200]};
  margin-bottom: 1em;
  padding-bottom: 1em;
`;

export const RoomTypeOption = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    width: 1.4em;
    height: auto;
    margin-right: 0.5em;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: ${grey[100]};
  color: ${grey[600]};
  margin-bottom: 0.5em;
  padding: 0 1em;
  position: relative;
`;

export const ToggleIcon = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  > svg {
    cursor: pointer;
    border-radius: 50%;
    box-sizing: content-box;
    padding: 0.5rem;
    font-size: 1.6em;
    transition: all 0.3s ease;

    &:hover {
      background-color: ${grey[200]};
    }
  }

  ${(props) => props.isClosed && css`
    justify-content: center;

    > svg {
      transform: rotateZ(180deg);
    }
  `}
`;

export const NotificationIcon = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-right: 0.5em;
  position: relative;

  font-size: 1.6em;
  color: ${grey[400]};

  > .num {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;
    height: 1rem;
    width: 1rem;
    transform: translate(50%, -25%);

    border-radius: 50rem;
    background-color: ${colors.groupizeBrand};
    color: white;
    font-size: 0.6rem;
  }
`;

export const GroupizeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.groupizeBrand};

  ${(props) => props.wrapped && css`
    background-color: ${colors.groupizeBrand};
    border-radius: 50%;
    color: white;
    height: 2em;
    width: 2em;
  `}
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 80vh;
  max-height: 100vh;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 1em;

  background-color: ${colors.groupizeBrand};
  color: white;
`;

export const ModalAction = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  cursor: pointer;
  font-size: 1.4em;
  margin: 0 1rem 0 0;

  ${(props) => props.right && css`
    margin: 0 0 0 auto;
  `}

  > svg {
    font-size: 2rem;
  }
`;

export const ModalTitle = styled.p`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 1.4em;
  font-weight: 400;
  margin: 0;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1em 1em 3em;
  overflow-y: auto;
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 300px;
  overflow: hidden;
  position: relative;
`;

export const FooterContentScrollable = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
`;

export const PanelTitle = styled.p`
  color: ${colors.groupizeBrand};
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  text-transform: uppercase;
`;

export const PanelText = styled.p`
  font-size: 0.9rem;
  line-height: 1.4;
  color: ${colors.grey800};
`;

export const QuestionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 1em;
`;
