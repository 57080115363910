import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from '../../../Common/Form/TextArea';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { i18n } from '../../../../src/custom/Internationalization';

function TellUsModal({
  openModal,
  eventData: { about },
  onTellUs,
  onClose,
}) {
  const [tellUsData, setTellUsData] = useState({
    about,
  });

  const data = {
    open: openModal,
    title: i18n.t('event_request_form.headings.tell_us_about'),
    maxWidth: 'md',
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.save'),
    showActionBtns: true,
  };

  return (
    <ConfirmationDialog
      {...data}
      handleConfirm={() => onTellUs(tellUsData)}
      handleCancel={onClose}
    >
      <TextArea
        id="about"
        onChange={(value) => setTellUsData({
          ...tellUsData,
          about: value,
        })}
        value={about || ''}
        nomargin
      />
    </ConfirmationDialog>
  );
}

TellUsModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onTellUs: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  eventData: PropTypes.shape().isRequired,
};

export default TellUsModal;
