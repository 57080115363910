import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FaUser,
  FaRegClock,
  FaPencilAlt,
  FaCocktail,
} from 'react-icons/fa';
import { GiKnifeFork, GiHamburger, GiGlassShot } from 'react-icons/gi';
import { FiCoffee } from 'react-icons/fi';
import Checkbox from '../../../../Common/Form/Checkbox/Checkbox';
import FullscreenDialog from '../../../../Dialogs/FullScreenDialog';
import DataPair from '../../../../Common/DataPair';
import Panel from '../../../../Common/Panel';
import {
  FlexFlow, PageSection, PageSectionTitle, PageText,
} from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';

import { EventRequirementsContext } from '../../context';
import { CheckboxWrapper, GroupizeIcon } from '../../styles';
import { i18n, i18nARAttr } from '../../../../../src/custom/Internationalization';
import FnBModal from './FnBModal';

const icons = [
  <GiKnifeFork />,
  <FiCoffee />,
  <GiHamburger />,
  <FiCoffee />,
  <FaCocktail />,
  <GiKnifeFork />,
  <GiGlassShot />,
];

function FoodBeverageForm({
  id,
  allFBOptions,
  event,
  isReadOnly,
}) {
  const sidebarDataContext = useContext(EventRequirementsContext);
  const { formData, formData: { fbOptions, fnbCustomData }, setField } = sidebarDataContext;

  const [isDetailsModalOpen, toggleDetailsModal] = useState(false);

  const handleChangeOption = (option) => {
    const newOptions = fbOptions || [];
    if (newOptions.includes(option)) {
      newOptions.splice(newOptions.findIndex((i) => i === option), 1);
    } else {
      newOptions.push(option);
    }
    setField('fbOptions', newOptions);
  };
  const generalOptions = fbOptions || [];
  const customOptions = fnbCustomData || [];
  const calcTypes = customOptions.reduce((accumulator, currentValue) => (
    accumulator + currentValue.fnbTypes.length
  ), 0);

  const isCustomized = fnbCustomData
    && fnbCustomData.length > 0
    && calcTypes > 0;

  return (
    <PageSection id={id}>
      <PageSectionTitle>
        <FiCoffee />
        {i18n.t('event_request_form.headings.food_and_beverage')}
      </PageSectionTitle>

      {isCustomized && formData.fnbCustomData.map(({ date, fnbTypes }) => (
        <Fragment key={date}>
          <PageSectionTitle>
            {moment(date).format('dddd, MMMM DD, YYYY')}
          </PageSectionTitle>

          {fnbTypes.map(({
            fnbId = id,
            attendeesNum,
            startTime,
            endTime,
            type,
          }) => (
            <Panel key={fnbId}>
              <PageText blueTitle />
              <FlexFlow justify="space-between">
                <DataPair
                  title={`${i18nARAttr('food_requirement.food_type')}:`}
                  value={i18n.t(`event_request_form.fb_options.${type}`)}
                />

                <DataPair
                  title={`${i18nARAttr('food_requirement.start_time')}:`}
                  value={(
                    <FlexFlow nowrap splitSpace="0.5em" align="center">
                      <FaRegClock />
                      <span>{moment(startTime, 'HH:mm A').format('h:mm A')}</span>
                    </FlexFlow>
                  )}
                />

                <DataPair
                  title={`${i18nARAttr('food_requirement.end_time')}:`}
                  value={(
                    <FlexFlow nowrap splitSpace="0.5em" align="center">
                      <FaRegClock />
                      <span>{moment(endTime, 'HH:mm A').format('h:mm A')}</span>
                    </FlexFlow>
                  )}
                />

                <DataPair
                  title={`${i18nARAttr('food_requirement.quantity')}:`}
                  value={(
                    <FlexFlow nowrap splitSpace="0.5em" align="center">
                      <FaUser />
                      <span>{attendeesNum}</span>
                    </FlexFlow>
                  )}
                />

                <DataPair
                  title={`${i18nARAttr('food_requirement.price')}:`}
                  value={i18n.t('event_request_form.messages.on_request')}
                />

              </FlexFlow>
            </Panel>
          ))}
        </Fragment>
      ))}

      {!isCustomized && allFBOptions.map(({ value, label }, i) => (
        <CheckboxWrapper key={value}>
          <Checkbox
            disabled={isReadOnly}
            label={label}
            checked={generalOptions.includes(value)}
            onChange={() => handleChangeOption(value)}
          />
          {icons[i]}
        </CheckboxWrapper>
      ))}

      {!isReadOnly && (
        <FlexFlow justify="flex-end">
          <GroupizeBtn onClick={() => toggleDetailsModal(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <GroupizeIcon wrapped>
                <FaPencilAlt />
              </GroupizeIcon>
              <span>{isCustomized ? i18n.t('actions.edit') : i18n.t('actions.customize')}</span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>
      )}

      <FullscreenDialog
        open={isDetailsModalOpen}
        handleClose={() => toggleDetailsModal(false)}
      >
        <FnBModal
          event={event}
          allFBOptions={allFBOptions}
          handleClose={() => toggleDetailsModal(false)}
        />
      </FullscreenDialog>
    </PageSection>
  );
}

FoodBeverageForm.defaultProps = {
  id: '',
  isReadOnly: false,
  allFBOptions: [],
};

FoodBeverageForm.propTypes = {
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  allFBOptions: PropTypes.arrayOf(PropTypes.shape()),
  event: PropTypes.shape().isRequired,
};

export default FoodBeverageForm;
