import React from 'react';
import PropTypes from 'prop-types';
import {
  HotelWrapper,
  HotelImage,
} from './styles';

function HotelItem({
  hotel: {
    name,
    hotelImage,
    fallbackImage,
  },
}) {
  return (
    <HotelWrapper>
      <HotelImage bgImage={hotelImage} fallbackImage={fallbackImage} />
      {name}
    </HotelWrapper>
  );
}

HotelItem.propTypes = {
  hotel: PropTypes.shape().isRequired,
};

export default HotelItem;
