import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FiCoffee,
  FiXCircle,
  FiChevronDown,
  FiPlus,
  FiTrash2,
} from 'react-icons/fi';
import { nanoid } from 'nanoid';
import Accordion from '../../../../Common/Accordion/Accordion';
import ConfirmationDialog from '../../../../Dialogs/ConfirmationDialog';
import { FlexFlow, PageSectionTitle } from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';

import AddDateModal from '../AddDateModal';
import FnBTypeForm from './FnBTypeForm';
import { EventRequirementsContext } from '../../context';
import {
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  ModalAction,
  ModalContent,
  GroupizeIcon,
} from '../../styles';
import { i18n } from '../../../../../src/custom/Internationalization';

function FnBModal({
  event,
  allFBOptions,
  handleClose,
}) {
  const sidebarDataContext = useContext(EventRequirementsContext);
  const {
    formData: {
      fbOptions,
      fnbCustomData,
      maxMeetingAttendees,
    }, setField,
  } = sidebarDataContext;

  const defaultModalData = {
    open: false,
    maxWidth: 'lg',
    showActionBtns: true,
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.confirm'),
  };

  const newFnBType = {
    id: 'new',
    type: '',
    price: '',
    attendeesNum: maxMeetingAttendees || 0,
    startTime: '7:00',
    endTime: '18:00',
  };

  const [showDateModal, setShowDateModal] = useState(false);
  const [newFnBData, setNewFnBData] = useState(newFnBType);
  const [roomAddModalData, setRoomAddModalData] = useState({
    ...defaultModalData,
    title: i18n.t('event_request_form.actions.add_type'),
    maxWidth: 'xl',
    showActionBtns: true,
    confirmBtnCopy: i18n.t('event_request_form.actions.add_type'),
    handleCancel: () => setRoomAddModalData({ ...roomAddModalData, open: false }),
    handleConfirm: () => setRoomAddModalData({ ...roomAddModalData, open: false }),
  });
  const [nextDay, setNextDay] = useState(event.endDate);
  const { startDate } = event;

  const defaultTypes = allFBOptions
    .filter(({ value }) => fbOptions.includes(value))
    .map(({ label }) => ({
      ...newFnBType,
      id: nanoid(),
      type: label,
    }));

  const [localFnBCustomData, setLocalFnBCustomData] = useState(fnbCustomData || []);
  const [confirmationModalData, setConfirmationModalData] = useState({
    open: false,
    title: i18n.t('actions.delete'),
    maxWidth: 'sm',
    showActionBtns: true,
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.delete'),
    handleCancel: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
    handleConfirm: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
  });

  const showConfirmationModal = ({ onConfirm }) => {
    setConfirmationModalData({
      ...confirmationModalData,
      handleConfirm: onConfirm,
      open: true,
    });
  };

  useEffect(() => {
    const getFnBByDate = (date) => {
      const defaultValue = {
        date,
        fnbTypes: defaultTypes,
      };
      if (localFnBCustomData && localFnBCustomData.length > 0) {
        const fnbDay = localFnBCustomData.find((i) => i.date === date);
        return fnbDay || defaultValue;
      } return defaultValue;
    };

    const getNumOfDays = (start, end) => {
      const momentStartDate = moment(start).utc();
      const momentEndDate = moment(end).utc();
      return momentEndDate.diff(momentStartDate, 'days');
    };

    if (!localFnBCustomData || localFnBCustomData.length === 0) {
      const newLocalFnBCustomData = [];
      const numOfDays = getNumOfDays(event.startDate, event.endDate);

      Object.keys(Array.from(new Array(numOfDays))).forEach((i) => {
        const currentNight = moment(event.startDate).add(i, 'd').format('YYYY-MM-DD');
        const fnbData = getFnBByDate(currentNight);
        newLocalFnBCustomData.push(fnbData);
      });

      setLocalFnBCustomData(newLocalFnBCustomData);
    }
  }, []);

  const setFnBTypeData = (newFnBValues, date) => {
    const { id: fnbId, ...otherFnBData } = newFnBValues;
    let newLocalFnBCustomData = localFnBCustomData || [];

    if (localFnBCustomData && localFnBCustomData.length > 0) {
      const fnbCurrentData = localFnBCustomData.find((rn) => rn.date === date);

      if (fnbCurrentData) {
        const { fnbTypes } = fnbCurrentData;
        let newFnBTypes = fnbTypes;

        if (fnbId === 'new') {
          newFnBTypes.push({ ...otherFnBData, id: nanoid() });
        } else {
          newFnBTypes = fnbTypes.map((type) => (type.id === fnbId
            ? { ...type, ...otherFnBData }
            : { ...type }));
        }
        newLocalFnBCustomData = localFnBCustomData.map((rn) => (rn.date === fnbCurrentData.date
          ? { ...rn, fnbTypes: newFnBTypes }
          : { ...rn }));
      } else {
        newLocalFnBCustomData.push({ date, fnbTypes: [{ ...otherFnBData, id: 0 }] });
      }
    } else {
      newLocalFnBCustomData.push({ date, fnbTypes: [{ ...otherFnBData, id: 0 }] });
    }

    setLocalFnBCustomData(newLocalFnBCustomData);
  };

  const removeFnBType = (typeId, date) => {
    let newLocalFnBCustomData = localFnBCustomData || [];

    if (localFnBCustomData && localFnBCustomData.length > 0) {
      const fnbCurrentData = localFnBCustomData.find((rn) => rn.date === date);

      if (fnbCurrentData) {
        const { fnbTypes } = fnbCurrentData;
        const typeIndex = fnbTypes.findIndex((i) => i.id === typeId);
        const updatedTypes = fnbTypes;

        if (typeIndex > -1) {
          updatedTypes.splice(typeIndex, 1);
        }

        newLocalFnBCustomData = localFnBCustomData.map((rn) => (rn.date === date
          ? { ...rn, fnbTypes: updatedTypes }
          : { ...rn }));
      }

      setLocalFnBCustomData(newLocalFnBCustomData);
    }
  };

  const duplicateFnBType = (typeId, date) => {
    let newLocalFnBCustomData = localFnBCustomData || [];

    if (localFnBCustomData && localFnBCustomData.length > 0) {
      const fnbCurrentData = localFnBCustomData.find((rn) => rn.date === date);

      if (fnbCurrentData) {
        const { fnbTypes } = fnbCurrentData;
        const type = fnbTypes.find((i) => i.id === typeId);
        const updatedTypes = fnbTypes;

        if (type) {
          updatedTypes.push({ ...type, id: nanoid() });
        }

        newLocalFnBCustomData = localFnBCustomData.map((rn) => (rn.date === date
          ? { ...rn, fnbTypes: updatedTypes }
          : { ...rn }));
      }

      setLocalFnBCustomData(newLocalFnBCustomData);
    }
  };

  const handleSave = () => {
    setField('fnbCustomData', localFnBCustomData);
    handleClose();
  };

  const removeDate = (date) => {
    const lsr = localFnBCustomData;
    const dateIndex = lsr.findIndex((mn) => mn.date === date);
    if (dateIndex > -1) {
      lsr.splice(dateIndex, 1);
    }

    setLocalFnBCustomData(lsr);
    setConfirmationModalData({ ...localFnBCustomData, open: false });
  };

  const addNewDate = (newDate) => {
    if (moment(newDate).isValid()) {
      const newDateFormatted = moment.utc(newDate).format('YYYY-MM-DD');
      const newLocalFnBCustomData = localFnBCustomData || [];
      const fnbType = newLocalFnBCustomData.find((rn) => rn.date === newDateFormatted);
      if (!fnbType) {
        newLocalFnBCustomData.push({
          date: newDateFormatted,
          fnbTypes: defaultTypes,
        });
      }
      setLocalFnBCustomData(newLocalFnBCustomData);

      const isAfterLatestDay = moment(newDate).add(1, 'd').isAfter(nextDay);
      if (isAfterLatestDay) {
        setNextDay(moment(newDate).add(1, 'd').format('YYYY-MM-DD'));
      }
    }
    setShowDateModal(false);
  };

  const showAddRoomModal = (night) => {
    setRoomAddModalData({
      ...roomAddModalData,
      open: true,
      currentNight: night,
    });
  };

  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalAction onClick={handleClose}>
          <FiXCircle />
        </ModalAction>

        <ModalTitle>
          {i18n.t('event_request_form.headings.customize_food_and_beverage_requirements')}
        </ModalTitle>

        <ModalAction right>
          <GroupizeBtn
            size="large"
            variant="contained"
            onClick={handleSave}
          >
            {i18n.t('actions.save')}
          </GroupizeBtn>
        </ModalAction>
      </ModalHeader>

      <ModalContent>
        <PageSectionTitle nomargin>
          <FiCoffee />
          {i18n.t('event_request_form.headings.food_and_beverage')}
        </PageSectionTitle>

        {localFnBCustomData.map(({ date, fnbTypes }) => {
          const friendlyDate = moment(date).format('dddd, MMMM DD, YYYY');
          const currentNight = moment(date).format('YYYY-MM-DD');

          return (
            <Accordion
              key={date}
              config={{
                isOpen: true,
                showDeleteIcon: true,
                titleIcon: <FiChevronDown />,
                titleText: friendlyDate,
                deleteIcon: <FiTrash2 />,
                deleteAction: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showConfirmationModal({
                    onConfirm: () => removeDate(date),
                  });
                },
              }}
            >
              <FlexFlow grow shrink column splitSpace="1em">
                {fnbTypes.map((fnbType) => (
                  <FnBTypeForm
                    key={fnbType.id}
                    hasActionBtns
                    fnbData={fnbType}
                    currentNight={currentNight}
                    setFnBTypeData={setFnBTypeData}
                    removeFnBType={removeFnBType}
                    duplicateFnBType={duplicateFnBType}
                    maxMeetingAttendees={maxMeetingAttendees}
                  />
                ))}

                <FlexFlow justify="flex-end">
                  <GroupizeBtn onClick={() => showAddRoomModal(currentNight)}>
                    <FlexFlow align="center" splitSpace="0.5em">
                      <GroupizeIcon wrapped>
                        <FiPlus />
                      </GroupizeIcon>
                      <span>{i18n.t('event_request_form.actions.add_type')}</span>
                    </FlexFlow>
                  </GroupizeBtn>
                </FlexFlow>
              </FlexFlow>
            </Accordion>
          );
        })}

        <FlexFlow margin="1em 0 0" justify="flex-end">
          <GroupizeBtn onClick={() => setShowDateModal(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <GroupizeIcon wrapped>
                <FiPlus />
              </GroupizeIcon>
              <span>{i18n.t('event_request_form.actions.add_date')}</span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>
      </ModalContent>

      <ConfirmationDialog
        open={roomAddModalData.open}
        title={roomAddModalData.title}
        maxWidth={roomAddModalData.maxWidth}
        showActionBtns={roomAddModalData.showActionBtns}
        cancelBtnCopy={roomAddModalData.cancelBtnCopy}
        confirmBtnCopy={roomAddModalData.confirmBtnCopy}
        handleCancel={roomAddModalData.handleCancel}
        handleConfirm={() => {
          setFnBTypeData(newFnBData, roomAddModalData.currentNight);
          setRoomAddModalData({ ...roomAddModalData, open: false });
        }}
      >
        <FnBTypeForm
          fnbData={newFnBData}
          currentNight={roomAddModalData.currentNight}
          setFnBTypeData={(fnbData) => setNewFnBData(fnbData)}
          maxMeetingAttendees={maxMeetingAttendees}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        open={confirmationModalData.open}
        title={confirmationModalData.title}
        maxWidth={confirmationModalData.maxWidth}
        showActionBtns={confirmationModalData.showActionBtns}
        cancelBtnCopy={confirmationModalData.cancelBtnCopy}
        confirmBtnCopy={confirmationModalData.confirmBtnCopy}
        handleCancel={confirmationModalData.handleCancel}
        handleConfirm={confirmationModalData.handleConfirm}
      >
        {i18n.t('form_tips.remove_confirmation')}
      </ConfirmationDialog>

      <AddDateModal
        isOpen={showDateModal}
        onCancel={() => setShowDateModal(false)}
        onConfirm={(v) => addNewDate(v)}
        startDate={startDate}
        endDate={nextDay}
      />
    </ModalWrapper>
  );
}

FnBModal.defaultProps = {
  allFBOptions: [],
};

FnBModal.propTypes = {
  allFBOptions: PropTypes.arrayOf(PropTypes.shape()),
  event: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FnBModal;
