/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { IoIosBed } from 'react-icons/io';
import {
  FiXCircle,
  FiChevronDown,
  FiPlus,
  FiTrash2,
} from 'react-icons/fi';
import Accordion from '../../../../Common/Accordion/Accordion';
import ConfirmationDialog from '../../../../Dialogs/ConfirmationDialog';
import { FlexFlow, PageSectionTitle } from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';

import AddDateModal from '../AddDateModal';
import RoomNightForm from './RoomNightForm';
import { EventRequirementsContext } from '../../context';
import {
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  ModalAction,
  ModalContent,
  GroupizeIcon,
} from '../../styles';

function SleepingRoomsModal({ handleClose }) {
  const sidebarDataContext = useContext(EventRequirementsContext);
  const { formData, setFormData } = sidebarDataContext;

  const {
    checkIn,
    checkOut,
    sleepingRoomNights,
    maxSleepingRooms,
    isSleepingRoomsCustomized,
  } = formData;

  const [showDateModal, setShowDateModal] = useState(false);
  const [localSleepingRoomNights, setLocalSleepingRoomNights] = useState(sleepingRoomNights || []);
  const [nextDay, setNextDay] = useState(checkOut);
  const startDate = checkIn;

  const newRoomsData = {
    roomSingle: formData.roomSingle || 0,
    roomDouble: formData.roomDouble || 0,
    roomSuite: formData.roomSuite || 0,
  };

  const [confirmationModalData, setConfirmationModalData] = useState({
    open: false,
    title: 'Delete',
    maxWidth: 'sm',
    showActionBtns: true,
    cancelBtnCopy: 'Cancel',
    confirmBtnCopy: 'Delete',
    handleCancel: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
    handleConfirm: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
  });

  const showConfirmationModal = ({ onConfirm }) => {
    setConfirmationModalData({
      ...confirmationModalData,
      handleConfirm: onConfirm,
      open: true,
    });
  };

  const setSleepingRoomNight = (roomData, date) => {
    let newSleepingRoomNights = localSleepingRoomNights || [];

    if (localSleepingRoomNights && localSleepingRoomNights.length > 0) {
      const roomNight = localSleepingRoomNights.find((rn) => rn.date === date);

      if (roomNight) {
        newSleepingRoomNights = localSleepingRoomNights.map((rn) => (rn.date === date
          ? { ...rn, rooms: roomData }
          : { ...rn }));
      } else {
        newSleepingRoomNights.push({ date, rooms: roomData });
      }
    } else {
      newSleepingRoomNights.push({ date, rooms: roomData });
    }

    setLocalSleepingRoomNights(newSleepingRoomNights);
  };

  const hasErrors = (srn) => {
    let hasError = false;
    const calculateRooms = ({
      roomSingle = 0,
      roomDouble = 0,
      roomSuite = 0,
    }) => (+roomSingle + +roomDouble + +roomSuite);

    const checkRoomNight = (rn) => calculateRooms(rn) > maxSleepingRooms;

    srn.forEach(({ rooms }) => {
      hasError = checkRoomNight(rooms) || hasError;
    });

    return hasError;
  };

  const handleSave = () => {
    const isValid = !hasErrors(localSleepingRoomNights);
    if (isValid) {
      setFormData({
        ...formData,
        sleepingRoomNights: localSleepingRoomNights,
        isSleepingRoomsCustomized: true,
      });
      handleClose();
    }
  };

  const updateRoomNights = (startDate, endDate) => {
    const getSleepingRoomsByDate = (date) => {
      const defaultValue = {
        date,
        rooms: { ...newRoomsData, id: nanoid() },
      };

      if (localSleepingRoomNights && localSleepingRoomNights.length > 0) {
        const roomNight = localSleepingRoomNights.find((rn) => rn.date === date);
        return roomNight || defaultValue;
      } return defaultValue;
    };

    const getNumOfDays = (start, end) => {
      const momentStartDate = moment(start).utc();
      const momentEndDate = moment(end).utc();
      return momentEndDate.diff(momentStartDate, 'days');
    };

    const newSleepingRoomNights = [];
    const numOfDays = getNumOfDays(startDate, endDate);

    Object.keys(Array.from(new Array((numOfDays > 0 ? numOfDays : 1)))).forEach((i) => {
      const currentNight = moment(startDate).add(i, 'd').format('YYYY-MM-DD');
      const sleepingRoomsData = getSleepingRoomsByDate(currentNight);
      newSleepingRoomNights.push(sleepingRoomsData);
    });

    setLocalSleepingRoomNights(newSleepingRoomNights);
  };

  useEffect(() => {
    if (!isSleepingRoomsCustomized) {
      updateRoomNights(checkIn, checkOut);
    }
  }, [isSleepingRoomsCustomized, checkIn, checkOut]);

  const removeDate = (date) => {
    const lsr = localSleepingRoomNights;
    const dateIndex = lsr.findIndex((mn) => mn.date === date);
    if (dateIndex > -1) {
      lsr.splice(dateIndex, 1);
    }

    setLocalSleepingRoomNights(lsr);
    setConfirmationModalData({ ...confirmationModalData, open: false });
  };

  const addNewDate = (newDate) => {
    if (moment(newDate).isValid()) {
      const newDateFormatted = moment.utc(newDate).format('YYYY-MM-DD');
      const newLocalSleepingRoomNights = localSleepingRoomNights || [];
      const roomNight = newLocalSleepingRoomNights.find((rn) => rn.date === newDateFormatted);
      if (!roomNight) {
        newLocalSleepingRoomNights.push({
          date: newDateFormatted,
          rooms: { ...newRoomsData, id: nanoid() },
        });
      }
      setLocalSleepingRoomNights(newLocalSleepingRoomNights);

      const isAfterLatestDay = moment(newDate).add(1, 'd').isAfter(nextDay);
      if (isAfterLatestDay) {
        setNextDay(moment(newDate).add(1, 'd').format('YYYY-MM-DD'));
      }
    }
    setShowDateModal(false);
  };

  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalAction onClick={handleClose}>
          <FiXCircle />
        </ModalAction>

        <ModalTitle>
          Customize Sleeping Room Requirements
        </ModalTitle>

        <ModalAction right>
          <GroupizeBtn
            size="large"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </GroupizeBtn>
        </ModalAction>
      </ModalHeader>

      <ModalContent>
        <PageSectionTitle nomargin>
          <IoIosBed />
          Sleeping Rooms
        </PageSectionTitle>

        {localSleepingRoomNights.map(({ date, rooms }) => {
          const friendlyDate = moment(date).format('dddd, MMMM DD, YYYY');
          const currentNight = moment(date).format('YYYY-MM-DD');

          return (
            <Accordion
              key={date}
              config={{
                isOpen: true,
                showDeleteIcon: true,
                titleIcon: <FiChevronDown />,
                titleText: friendlyDate,
                deleteIcon: <FiTrash2 />,
                deleteAction: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showConfirmationModal({
                    onConfirm: () => removeDate(date),
                  });
                },
              }}
            >
              <RoomNightForm
                {...rooms}
                currentNight={currentNight}
                setSleepingRoomNight={setSleepingRoomNight}
                maxRooms={maxSleepingRooms}
              />
            </Accordion>
          );
        })}

        <FlexFlow margin="1em 0 0" justify="flex-end">
          <GroupizeBtn onClick={() => setShowDateModal(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <GroupizeIcon wrapped>
                <FiPlus />
              </GroupizeIcon>
              <span>Add Date</span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>

        <AddDateModal
          isOpen={showDateModal}
          onCancel={() => setShowDateModal(false)}
          onConfirm={(v) => addNewDate(v)}
          startDate={startDate}
          endDate={nextDay}
        />
      </ModalContent>

      <ConfirmationDialog {...confirmationModalData}>
        Are you sure?
      </ConfirmationDialog>
    </ModalWrapper>
  );
}

SleepingRoomsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default SleepingRoomsModal;
