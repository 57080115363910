import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FiMonitor,
  FiXCircle,
  FiChevronDown,
  FiPlus,
  FiTrash2,
} from 'react-icons/fi';
import TextArea from '../../../../Common/Form/TextArea';
import Accordion from '../../../../Common/Accordion/Accordion';
import ConfirmationDialog from '../../../../Dialogs/ConfirmationDialog';
import { FlexFlow, PageSectionTitle } from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';

import { EventRequirementsContext } from '../../context';
import AddDateModal from '../AddDateModal';
import {
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  ModalAction,
  ModalContent,
  GroupizeIcon,
} from '../../styles';
import { i18n } from '../../../../../src/custom/Internationalization';

function AVModalForm({
  event,
  allAVOptions,
  handleClose,
}) {
  const sidebarDataContext = useContext(EventRequirementsContext);
  const {
    formData: {
      avOptions,
      customAvOptions,
    }, setField,
  } = sidebarDataContext;

  const defaultOptions = allAVOptions
    .filter(({ value }) => avOptions.includes(value))
    .map(({ label }) => label);

  const [showDateModal, setShowDateModal] = useState(false);
  const [localAVOptions, setLocalAVOptions] = useState(customAvOptions || (defaultOptions && defaultOptions.join(', ')) || '');
  const [confirmationModalData, setConfirmationModalData] = useState({
    open: false,
    title: i18n.t('actions.delete'),
    maxWidth: 'sm',
    showActionBtns: true,
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.delete'),
    handleCancel: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
    handleConfirm: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
  });
  const [nextDay, setNextDay] = useState(event.endDate);
  const { startDate } = event;

  const showConfirmationModal = ({ onConfirm }) => {
    setConfirmationModalData({
      ...confirmationModalData,
      handleConfirm: onConfirm,
      open: true,
    });
  };

  const setCustomAVOptions = (newAVOptions, date) => {
    let newLocalAVOptions = localAVOptions || [];

    if (localAVOptions && localAVOptions.length > 0) {
      const avOptionsDay = localAVOptions.find((rn) => rn.date === date);

      if (avOptionsDay) {
        newLocalAVOptions = localAVOptions.map((rn) => (rn.date === date
          ? { ...rn, options: newAVOptions }
          : { ...rn }));
      } else {
        newLocalAVOptions.push({ date, options: newAVOptions });
      }
    } else {
      newLocalAVOptions.push({ date, options: newAVOptions });
    }

    setLocalAVOptions(newLocalAVOptions);
  };

  const handleSave = () => {
    setField('customAvOptions', localAVOptions);
    handleClose();
  };

  useEffect(() => {
    const getCustomAVOptionsByDate = (date) => {
      const defaultValue = { date, options: defaultOptions && defaultOptions.join(', ') };

      if (localAVOptions && localAVOptions.length > 0) {
        const avOptionsDay = localAVOptions.find((rn) => rn.date === date);
        return avOptionsDay || defaultValue;
      } return defaultValue;
    };

    const getNumOfDays = (start, end) => {
      const momentStartDate = moment(start).utc();
      const momentEndDate = moment(end).utc();
      return momentEndDate.diff(momentStartDate, 'days');
    };

    if (!localAVOptions || localAVOptions.length === 0) {
      const newLocalAVOptions = [];
      const numOfDays = getNumOfDays(event.startDate, event.endDate);

      Object.keys(Array.from(new Array(numOfDays))).forEach((i) => {
        const currentNight = moment(event.startDate).add(i, 'd').format('YYYY-MM-DD');
        const AVData = getCustomAVOptionsByDate(currentNight);
        newLocalAVOptions.push(AVData);
      });

      setLocalAVOptions(newLocalAVOptions);
    }
  }, []);

  const removeDate = (date) => {
    const lsr = localAVOptions;
    const dateIndex = lsr.findIndex((mn) => mn.date === date);
    if (dateIndex > -1) {
      lsr.splice(dateIndex, 1);
    }

    setLocalAVOptions(lsr);
    setConfirmationModalData({ ...localAVOptions, open: false });
  };

  const addNewDate = (newDate) => {
    if (moment(newDate).isValid()) {
      const newDateFormatted = moment.utc(newDate).format('YYYY-MM-DD');
      const newLocalAVOptions = localAVOptions || [];
      const avDay = newLocalAVOptions.find((rn) => rn.date === newDateFormatted);
      if (!avDay) {
        newLocalAVOptions.push({
          date: newDateFormatted,
          options: defaultOptions && defaultOptions.join(', '),
        });
      }
      setLocalAVOptions(newLocalAVOptions);
      const isAfterLatestDay = moment(newDate).add(1, 'd').isAfter(nextDay);
      if (isAfterLatestDay) {
        setNextDay(moment(newDate).add(1, 'd').format('YYYY-MM-DD'));
      }
    }
    setShowDateModal(false);
  };

  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalAction onClick={handleClose}>
          <FiXCircle />
        </ModalAction>

        <ModalTitle>
          {i18n.t('event_request_form.headings.customize_audio_visual_options')}
        </ModalTitle>

        <ModalAction right>
          <GroupizeBtn
            size="large"
            variant="contained"
            onClick={handleSave}
          >
            {i18n.t('actions.save')}
          </GroupizeBtn>
        </ModalAction>
      </ModalHeader>

      <ModalContent>
        <PageSectionTitle nomargin>
          <FiMonitor />
          {i18n.t('event_request_form.headings.audio_visual')}
        </PageSectionTitle>

        {localAVOptions.map(({ date, options }) => {
          const friendlyDate = moment(date).format('dddd, MMMM DD, YYYY');
          const currentNight = moment(date).format('YYYY-MM-DD');

          return (
            <Accordion
              key={date}
              config={{
                isOpen: true,
                showDeleteIcon: true,
                titleIcon: <FiChevronDown />,
                titleText: friendlyDate,
                deleteIcon: <FiTrash2 />,
                deleteAction: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showConfirmationModal({
                    onConfirm: () => removeDate(date),
                  });
                },
              }}
            >
              <TextArea
                id="AV_custom_options"
                label={i18n.t('event_request_form.messages.av_options')}
                value={options}
                onChange={(value) => setCustomAVOptions(value, currentNight)}
                nomargin
                flexGrow
              />
            </Accordion>
          );
        })}

        <FlexFlow margin="1em 0 0" justify="flex-end">
          <GroupizeBtn onClick={() => setShowDateModal(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <GroupizeIcon wrapped>
                <FiPlus />
              </GroupizeIcon>
              <span>{i18n.t('event_request_form.actions.add_date')}</span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>

        <ConfirmationDialog
          open={confirmationModalData.open}
          title={confirmationModalData.title}
          maxWidth={confirmationModalData.maxWidth}
          showActionBtns={confirmationModalData.showActionBtns}
          cancelBtnCopy={confirmationModalData.cancelBtnCopy}
          confirmBtnCopy={confirmationModalData.confirmBtnCopy}
          handleCancel={confirmationModalData.handleCancel}
          handleConfirm={confirmationModalData.handleConfirm}
        >
          {i18n.t('form_tips.remove_confirmation')}
        </ConfirmationDialog>

        <AddDateModal
          isOpen={showDateModal}
          onCancel={() => setShowDateModal(false)}
          onConfirm={(v) => addNewDate(v)}
          startDate={startDate}
          endDate={nextDay}
        />
      </ModalContent>
    </ModalWrapper>
  );
}

AVModalForm.defaultProps = {
  allAVOptions: [],
};

AVModalForm.propTypes = {
  allAVOptions: PropTypes.arrayOf(PropTypes.shape()),
  event: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AVModalForm;
