import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import {
  FieldWrapper,
  DateFieldInput,
  InputIcon,
  StyledInput,
  StyledInlineInput,
} from './styles';
import 'react-datepicker/dist/react-datepicker.css';

function DateField({
  id,
  label,
  value,
  variant,
  isDisabled,
  noMargin,
  onDateSelected,
  isOpened,
  minheight,
  startAdornment,
}) {
  const utcDate = (date) => (moment.utc(date, 'YYYY-MM-DD'));
  const utcLocalDate = (date) => (moment.utc(date, 'YYYY-MM-DD').local());
  const [dateValue, setDateValue] = useState(utcLocalDate(value).toDate());

  const handleOnChange = (date) => {
    const momentDate = utcDate(date).format('YYYY-MM-DD');
    const momentLocalDate = utcLocalDate(date).toDate();

    onDateSelected(momentDate);
    setDateValue(momentLocalDate);
  };

  useEffect(() => {
    const newValue = value ? utcLocalDate(value).toDate() : '';
    setDateValue(newValue);
  }, [value]);

  const startContent = startAdornment ? (
    <InputIcon position="start">
      {startAdornment}
    </InputIcon>
  ) : null;

  const renderCustomInput = ({ date, onClick }) => {
    const inputParams = {
      fullWidth: true,
      value: date ? moment.utc(date).format('ddd, MMM DD, YYYY') : '',
      onClick,
      disabled: isDisabled,
      startAdornment: startContent,
    };

    let customInput;
    switch (variant) {
      case 'simple':
      case 'inline':
        customInput = <StyledInlineInput {...inputParams} />;
        break;
      default:
        customInput = <StyledInput {...inputParams} />;
        break;
    }

    return customInput;
  };

  const CustomInputDate = forwardRef(({ value: date, onClick }, ref) => (
    <div ref={ref}>
      {renderCustomInput({ date, onClick })}
    </div>
  ));

  return (
    <FieldWrapper
      flexGrow={1}
      noMargin={noMargin}
      minheight={minheight}
      variant={variant}
    >
      {label && label.length > 0 && (
        <label htmlFor={id}>{label}</label>
      )}
      <DateFieldInput>
        <DatePicker
          id={id}
          disabled={isDisabled}
          inline={isOpened}
          selected={dateValue}
          showMonthDropdown
          showYearDropdown
          useWeekdaysShort
          onChange={(date) => handleOnChange(date)}
          customInput={<CustomInputDate isDisabled={isDisabled} />}
        />
      </DateFieldInput>
    </FieldWrapper>
  );
}

DateField.defaultProps = {
  label: '',
  value: null,
  isDisabled: false,
  isOpened: false,
  noMargin: false,
  variant: '',
  anchorDirection: 'left',
  minheight: '0',
  onDateSelected: () => { },
  startAdornment: null,
};

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  noMargin: PropTypes.bool,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  isOpened: PropTypes.bool,
  onDateSelected: requiredIf(PropTypes.func, (props) => !props.isDisabled),
  anchorDirection: PropTypes.string,
  minheight: PropTypes.string,
  startAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default DateField;
