import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { FaPencilAlt } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import { GroupizeBtn } from "../../../Common/helpers/styles";
import TextField from "../../../Common/Form/TextField";
import TextArea from "../../../Common/Form/TextArea";
import {
  FlexFlow,
  PageSection,
  PageSectionTitle,
} from "../../../Common/helpers/DisplayHelpers";
import { EventRequirementsContext } from "../context";
import TellUsModel from "./TellUsModal";
import { i18n, i18nARAttr } from "../../../../src/custom/Internationalization";

function AboutForm({ id, isReadOnly, onTellUs }) {
  const sidebarDataContext = useContext(EventRequirementsContext);
  const { formData, setField } = sidebarDataContext;
  const [isTellUsModelOpened, setTellUsModelOpened] = useState(false);
  const dataContext = useContext(EventRequirementsContext);
  const { handleTellUs } = dataContext;

  return (
    <PageSection id={id}>
      <PageSectionTitle>
        <FaPencilAlt />
        {i18n.t("event_request_form.headings.tell_us_about")}!
      </PageSectionTitle>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          size="small"
          label={i18nARAttr("event.name")}
          id="event_name"
          value={formData.name || ""}
          onChange={(e) => setField("name", e.target.value)}
          flexGrow
          nomargin
          minheight="30px"
          variant="outlined"
          disabled={isReadOnly}
        />
      </Grid>
      <TextArea
        disabled={isReadOnly}
        id="about_event"
        value={formData.about || ""}
        onChange={(value) => setField("about", value)}
        placeholder={i18n.t(
          "event_request_form.placeholders.additional_information",
        )}
        className="tw-mt-4"
        nomargin
        flexGrow
        autoComplete="none"
      />
      {isReadOnly && (
        <FlexFlow justify="flex-end">
          <GroupizeBtn onClick={() => setTellUsModelOpened(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <span>{i18n.t("actions.edit")}</span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>
      )}
      <TellUsModel
        openModal={isTellUsModelOpened}
        onClose={() => {
          setTellUsModelOpened(false);
        }}
        eventData={{ about: formData.about }}
        onTellUs={(data) => {
          handleTellUs(data);
          setTellUsModelOpened(false);
        }}
      />
    </PageSection>
  );
}

AboutForm.defaultProps = {
  id: "",
  isReadOnly: false,
};

AboutForm.propTypes = {
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  onTellUs: PropTypes.func,
};

export default AboutForm;
