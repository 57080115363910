import styled, { css } from 'styled-components';
import { grey } from '@mui/material/colors';

export const HotelWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid ${grey[200]};
  margin: 0.5em 0;
  padding: 0.5em;
  align-items: center;
`;

export const HotelImage = styled.div`
  background-color: ${grey[50]};
  height: 3em;
  width: 3em;
  margin-right: 1em;

  ${(props) => props.bgImage && css`
    background-image: url("${props.bgImage}"), url("${props.fallbackImage}");
    background-repeat: no-repeat;
    background-size: cover;
  `}
`;
