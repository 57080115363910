import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { blue } from '@mui/material/colors';

const MessageWrapper = styled.div`
  padding: 1em;
  border: 1px solid ${blue[700]};
  background-color: ${blue[50]};
  border-radius: 6px;
  color: ${blue[900]};
  margin-bottom: 1em;

  svg {
    width: 2em;
    height: 2em;
  }
`;

function NotificationMessage({ children }) {
  return (
    <MessageWrapper>
      {children}
    </MessageWrapper>
  );
}

NotificationMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NotificationMessage;
