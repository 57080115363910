import styled, { css } from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import colors from '../../Common/constants/colors';

export const StyledLabel = MuiStyled(InputLabel)({
  marginBottom: '0.5em',
});

export const StyledInput = MuiStyled(InputBase)({
  backgroundColor: colors.white,
  color: colors.grey600,
  fontSize: '0.9em',
  minHeight: '0',
  position: 'relative',
  zIndex: '1',
});

export const StyledInlineInput = MuiStyled(StyledInput)({
  border: `1px solid ${colors.grey200}`,
  borderRadius: '6px',
  boxSizing: 'border-box',
  flex: '1 1 auto',
  padding: '0.5rem 1rem',
  '&.Mui-error': {
    borderColor: colors.red600,
  },
});

export const InputIcon = MuiStyled(InputAdornment)({
  color: colors.grey400,
  minWidth: '1.5rem',
  flex: '0 0 auto',
});

export const StyledButton = MuiStyled(Button)((props) => ({
  backgroundColor: props.bgColor || colors.groupizeBrand,
  borderColor: props.bgColor || colors.groupizeBrand,
  color: props.textColor || colors.grey50,
  '&:hover': {
    backgroundColor: props.bgColor || colors.groupizeBrand,
    borderColor: props.bgColor || colors.groupizeBrand,
  },
}));

export const FieldInputContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: ${(props) => (props.flexGrow ? 1 : 0)};
  justify-content: flex-start;

  background-color: ${(props) => props.bgColor || '#ffffff'};
  border: 1px solid ${colors.grey200};
  border-radius: 6px;
  box-sizing: border-box;

  margin: 0.5em;
  min-width: 70px;
  min-height: ${(props) => (props.minheight || '0')};
  padding: ${(props) => (props.padding || '0.5em 1em')};
  position: relative;

  .MuiInputBase-root {
    min-height: ${(props) => (props.minheight || '20px')} !important;
  }

  label {
    color: ${colors.grey600};
    font-size: 0.9em;
    margin-bottom: 0.5rem;
  }

  input {
    font-size: 0.9em;
    padding: 0;
  }

  textarea {
    background-color: #ffffff;
    color: ${colors.grey600};
    min-height: 150px;
  }

  ${(props) => (props.variant === 'simple') && css`
    background-color: inherit;
    border: none;
    padding: ${props.padding || '0.5em 0'};

    .MuiInputBase-root {
      min-height: ${(props) => (props.minheight || '0')};
    }
  `};

  ${(props) => (props.variant === 'inline') && css`
    flex-flow: row nowrap;
    align-items: center;
    border: none;
    padding: ${props.padding || '0.5em 0'};

    label {
      margin: 0 1em 0 0;
      min-width: 100px;
    }

    > .MuiFormGroup-root {
      flex-direction: row;

      label {
        min-width: 0;
      }
    }
  `}

  ${(props) => props.small && css`
    margin: 0.25em;

    input {
      font-size: 0.9em;
    }
  `}

  ${(props) => props.noWrapper && css`
    background-color: transparent;
    border: none;
    padding: 0;
  `}

  ${(props) => props.noMargin && css`
    margin: 0;
  `}
`;

export const AutoCompleteContainer = styled.div`
  border: 1px solid ${colors.grey200};
  border-radius: 6px;

  position: absolute;
  left: 0;
  top: 100%;
  z-index: 999;
`;

export const AutoCompleteItem = styled.div`
  background-color: ${colors.white};
  cursor: pointer;

  padding: 0.5em 1em;
  transition: background-color 0.3s ease;

  &.active {
    background-color: ${colors.grey50};
  }
`;

export const DateFieldInput = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;

  .react-datepicker-wrapper {
    display: flex;
    flex: 1 1 auto;
  }

  .react-datepicker-popper {
    z-index: 9999;
  }

  .react-datepicker__header__dropdown {
    padding: 0.5em;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    top: 5px;
  }

  .DateInput_fang {
    top: 22px !important;
    z-index: 999 !important;
  }

  .SingleDatePicker_picker {
    top: 33px !important;
    z-index: 998 !important;
  }

  .DateInput_input {
    box-sizing: border-box;
    padding: 0;

    color: ${colors.grey600};
    font-size: 0.9em;
    line-height: 1;
    border-bottom: none;
  }

  ${(props) => props.variant === 'inline' && css`
    background-color: #ffffff;
    border: 1px solid ${colors.grey200};
    border-radius: 6px;
    box-sizing: border-box;
    flex: 1 1 auto;
    padding: 0.5rem 1rem;
  `}
`;

export const ColorButton = styled(Button)`
  background-color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;
  border-color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;

  &.MuiButton-outlined {
    background-color: transparent !important;
    color: ${(props) => props.bgcolor || colors.groupizeBrand} !important;
  }
`;
