/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ModalSectionContent } from '../Common/helpers/DisplayHelpers';

const TransitionUp = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

function FullscreenDialog({
  open,
  handleClose,
  children,
}) {
  return (
    <Dialog
      fullWidth
      fullScreen
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      aria-labelledby="fullscreen-modal"
      TransitionComponent={TransitionUp}
    >
      <ModalSectionContent id="modalContent">
        {children}
      </ModalSectionContent>
    </Dialog>
  );
}

FullscreenDialog.defaultProps = {
  open: false,
  children: null,
};

FullscreenDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FullscreenDialog;
