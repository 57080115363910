import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FiMonitor,
  FiHardDrive,
  FiWifi,
  FiPhoneCall,
  FiMic,
} from 'react-icons/fi';
import {
  FaPencilAlt,
  FaChalkboard,
} from 'react-icons/fa';

import Checkbox from '../../../../Common/Form/Checkbox/Checkbox';
import FullscreenDialog from '../../../../Dialogs/FullScreenDialog';
import DataPair from '../../../../Common/DataPair';
import Panel from '../../../../Common/Panel';
import {
  FlexFlow, PageSection, PageSectionTitle, PageText,
} from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';

import { EventRequirementsContext } from '../../context';
import { CheckboxWrapper, GroupizeIcon } from '../../styles';
import { i18n } from '../../../../../src/custom/Internationalization';
import AVModalForm from './AVModalForm';

// TODO: remove this in reg_app
const icons = [
  <FiMonitor />,
  <FiHardDrive />,
  <FiWifi />,
  <FiPhoneCall />,
  <FaChalkboard />,
  <FiMic />,
];

function AudioVisualForm({
  id,
  allAVOptions,
  event,
  isReadOnly,
}) {
  const sidebarDataContext = useContext(EventRequirementsContext);
  const { formData: { avOptions, customAvOptions }, setField } = sidebarDataContext;
  const [isDetailsModalOpen, toggleDetailsModal] = useState(false);

  const AVCustomDays = customAvOptions || [];
  const calcAVDays = AVCustomDays.reduce((accumulator, currentValue) => (
    accumulator + currentValue.options.length
  ), 0);
  const isCustomized = customAvOptions
    && customAvOptions.length > 0
    && calcAVDays > 0;

  const handleChangeOption = (option) => {
    const newOptions = avOptions || [];
    if (newOptions.includes(option)) {
      newOptions.splice(newOptions.findIndex((i) => i === option), 1);
    } else {
      newOptions.push(option);
    }
    setField('avOptions', newOptions);
  };

  const currentOptions = avOptions || [];

  return (
    <PageSection id={id}>
      <PageSectionTitle>
        <FiMonitor />
        {i18n.t('event_request_form.headings.audio_visual')}
      </PageSectionTitle>

      {isCustomized && customAvOptions.map(({ date, options }) => (
        <Fragment key={date}>
          <PageSectionTitle>
            {moment(date).format('dddd, MMMM DD, YYYY')}
          </PageSectionTitle>

          <Panel>
            <PageText blueTitle />

            <DataPair
              title={`${i18n.t('event_request_form.messages.options')}...`}
              value={options}
            />
          </Panel>
        </Fragment>
      ))}

      {!isCustomized && allAVOptions.map(({ value, label }, i) => (
        <CheckboxWrapper key={value}>
          <Checkbox
            disabled={isReadOnly}
            label={label}
            checked={currentOptions.includes(value)}
            onChange={() => handleChangeOption(value)}
          />
          {icons[i]}
        </CheckboxWrapper>
      ))}

      {!isReadOnly && (
        <FlexFlow justify="flex-end">
          <GroupizeBtn onClick={() => toggleDetailsModal(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <GroupizeIcon wrapped>
                <FaPencilAlt />
              </GroupizeIcon>
              <span>{isCustomized ? i18n.t('actions.edit') : i18n.t('actions.customize')}</span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>
      )}

      <FullscreenDialog
        open={isDetailsModalOpen}
        handleClose={() => toggleDetailsModal(false)}
      >
        <AVModalForm
          event={event}
          allAVOptions={allAVOptions}
          handleClose={() => toggleDetailsModal(false)}
        />
      </FullscreenDialog>
    </PageSection>
  );
}

AudioVisualForm.defaultProps = {
  id: '',
  isReadOnly: false,
  allAVOptions: [],
};

AudioVisualForm.propTypes = {
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  allAVOptions: PropTypes.arrayOf(PropTypes.shape()),
  event: PropTypes.shape().isRequired,
};

export default AudioVisualForm;
