import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FaUser,
  FaRegClock,
  FaPencilAlt,
} from 'react-icons/fa';
import { FiTrash2, FiCopy } from 'react-icons/fi';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TimeField from '../../../../Common/Form/TimeField/TimeField';
import TextField from '../../../../Common/Form/TextField';
import SelectField from '../../../../Common/Form/SelectField/SelectField';
import ConfirmationDialog from '../../../../Dialogs/ConfirmationDialog';
import { FlexFlow, PageSectionTitle } from '../../../../Common/helpers/DisplayHelpers';
import NumberField from '../../../../Common/Form/NumberField';

import { RoomNightWrapper, RoomTypeOption } from '../../styles';
import { i18n, i18nARAttr } from '../../../../../src/custom/Internationalization';

const roomTypes = [
  { value: 'boardroom', label: i18n.t('ebids.setup.boardroom') },
  { value: 'classroom', label: i18n.t('ebids.setup.classroom') },
  { value: 'crescent-rounds', label: i18n.t('ebids.setup.crescent-rounds') },
  { value: 'hollow-square', label: i18n.t('ebids.setup.hollow-square') },
  { value: 'rounds', label: i18n.t('ebids.setup.rounds') },
  { value: 'theatre-style', label: i18n.t('ebids.setup.theatre-style') },
  { value: 'u-shape', label: i18n.t('ebids.setup.u-shape') },
  { value: 'other', label: i18n.t('ebids.setup.other') },
];

const meetingRoomTypes = [
  i18n.t('event_request_form.meeting_room_types.breakfast'),
  i18n.t('event_request_form.meeting_room_types.breakout'),
  i18n.t('event_request_form.meeting_room_types.dinner'),
  i18n.t('event_request_form.meeting_room_types.general'),
  i18n.t('event_request_form.meeting_room_types.lunch'),
  i18n.t('event_request_form.meeting_room_types.meeting'),
  i18n.t('event_request_form.meeting_room_types.reception'),
  i18n.t('event_request_form.meeting_room_types.registration'),
  i18n.t('event_request_form.meeting_room_types.other'),
];

const roomOptions = roomTypes.map(({ value, label }) => ({
  value,
  label: (
    <RoomTypeOption key={label}>
      <img src={`https://groupizereg-production.s3.amazonaws.com/images/meeting_types/style-diagram-${value}.jpg`} />
      {label}
    </RoomTypeOption>
  ),
}));

function RoomNightForm({
  roomData,
  currentNight,
  setRoomNight,
  removeRoomNight,
  duplicateRoomNight,
  hasActionBtns,
  maxMeetingAttendees,
}) {
  const setRoomData = (name, value) => {
    const newData = {};
    newData[name] = value;
    const newMRData = { ...roomData, ...newData };
    setRoomNight(newMRData, currentNight);
  };

  const [confirmationModalData, setConfirmationModalData] = useState({
    open: false,
    title: i18n.t('actions.delete'),
    maxWidth: 'sm',
    showActionBtns: true,
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.delete'),
    handleCancel: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
    handleConfirm: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
  });

  const showConfirmationModal = ({ onConfirm }) => {
    setConfirmationModalData({
      ...confirmationModalData,
      handleConfirm: onConfirm,
      open: true,
    });
  };

  const {
    id,
    roomName,
    type,
    startTime,
    attendeesNum,
  } = roomData;

  return (
    <RoomNightWrapper>
      <FlexFlow
        justify="space-between"
        align="center"
        padding="0 0 1rem"
      >
        <PageSectionTitle nomargin noPadding>
          {i18n.t('event_request_form.headings.room_name', { name: roomName })}
        </PageSectionTitle>
        {hasActionBtns && (
          <FlexFlow nowrap splitSpace="1em">
            <IconButton onClick={() => duplicateRoomNight(id, currentNight)}>
              <FiCopy />
            </IconButton>
            <IconButton
              onClick={() => showConfirmationModal({
                onConfirm: () => removeRoomNight(id, currentNight),
              })}
            >
              <FiTrash2 />
            </IconButton>
          </FlexFlow>
        )}
      </FlexFlow>
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} sm={12} md={5}>
          <FlexFlow column splitSpace="1em">
            <TextField
              variant="inline"
              nomargin
              id="roomName"
              label={i18nARAttr('meeting_room_day.room_name')}
              value={roomData.roomName}
              onChange={(e) => setRoomData('roomName', e.target.value)}
              minheight="40px"
              startAdornment={<FaPencilAlt />}
            />

            <TimeField
              variant="inline"
              nomargin
              id="startTime"
              label={i18nARAttr('meeting_room_day.start_time')}
              defaultValue={startTime}
              onChanged={(value) => setRoomData('startTime', value)}
              minheight="40px"
              startAdornment={<FaRegClock />}
            />

            <NumberField
              variant="inline"
              nomargin
              id="attendees_num"
              label={i18nARAttr('meeting_room_day.number_of_attendees')}
              defaultNum={attendeesNum}
              min={0}
              max={maxMeetingAttendees || null}
              onChanged={(value) => setRoomData('attendeesNum', +value)}
              minheight="40px"
              startAdornment={<FaUser />}
            />
          </FlexFlow>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <FlexFlow column splitSpace="1em">
            <SelectField
              variant="inline"
              id="event_type"
              name="event_type"
              label={i18nARAttr('meeting_room_day.meeting_type')}
              value={type || ''}
              items={meetingRoomTypes.map((rt) => ({ label: rt, value: rt }))}
              onChange={(val) => setRoomData('type', val)}
              minheight="40px"
            />

            <TimeField
              variant="inline"
              nomargin
              id="endTime"
              label={i18nARAttr('meeting_room_day.end_time')}
              defaultValue={roomData.endTime}
              onChanged={(value) => setRoomData('endTime', value)}
              minheight="40px"
              startAdornment={<FaRegClock />}
            />
            <SelectField
              variant="inline"
              id="event_setup"
              name="event_setup"
              label={i18nARAttr('meeting_room_day.room_setup')}
              defaultValue="Default"
              value={roomData.setup || ''}
              items={roomOptions}
              minheight="40px"
              onChange={(val) => setRoomData('setup', val)}
            />
          </FlexFlow>
        </Grid>
      </Grid>

      <ConfirmationDialog {...confirmationModalData}>
        {i18n.t('form_tips.remove_confirmation')}
      </ConfirmationDialog>
    </RoomNightWrapper>
  );
}

RoomNightForm.defaultProps = {
  hasActionBtns: false,
  currentNight: '',
  removeRoomNight: () => true,
  duplicateRoomNight: () => true,
  maxMeetingAttendees: null,
};

RoomNightForm.propTypes = {
  hasActionBtns: PropTypes.bool,
  currentNight: PropTypes.string,
  roomData: PropTypes.shape().isRequired,
  setRoomNight: PropTypes.func.isRequired,
  removeRoomNight: PropTypes.func,
  duplicateRoomNight: PropTypes.func,
  maxMeetingAttendees: PropTypes.number,
};

export default RoomNightForm;
