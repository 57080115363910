/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import * as yup from 'yup';
import { createYupSchema } from '../../../Common/helpers/yupSchemaCreator';
import { i18n, i18nARAttr, i18nARError } from '../../../../src/custom/Internationalization';

export const Context = createContext({});

const schema = yup.object().shape({
  maxSleepingRooms: yup
    .number()
    .nullable(),
  roomSingle: yup
    .number()
    .test('max', i18n.t('event_request_form.errors.total_cannot_exceed'), function (value = 0) {
      const { roomDouble, roomSuite, maxSleepingRooms } = this.parent;
      return value <= maxSleepingRooms - roomDouble - roomSuite;
    }),
  roomDouble: yup
    .number()
    .test('max', i18n.t('event_request_form.errors.total_cannot_exceed'), function (value = 0) {
      const { roomSingle, roomSuite, maxSleepingRooms } = this.parent;
      return value <= maxSleepingRooms - roomSingle - roomSuite;
    }),
  roomSuite: yup
    .number()
    .test('max', i18n.t('event_request_form.errors.total_cannot_exceed'), function (value = 0) {
      const { roomDouble, roomSingle, maxSleepingRooms } = this.parent;
      return value <= maxSleepingRooms - roomDouble - roomSingle;
    }),
  meetingStartDate: yup
    .string()
    .nullable(),
  meetingEndDate: yup
    .string()
    .test('isAfter', `${i18nARAttr('event.end_date')} ${i18n.t('activerecord.errors.models.event.attributes.end_date.must_be_after_start_date')}}`, function (currentValue) {
      const { meetingStartDate } = this.parent;
      return !meetingStartDate
        || !currentValue
        || (
          meetingStartDate
          && currentValue
          && moment(meetingStartDate) <= moment(currentValue)
        );
    })
    .nullable(),
  budgetCodeRequired: yup.boolean(),
  costCenterRequired: yup.boolean(),
  budgetCode: yup
    .string()
    .nullable()
    .when('budgetCodeRequired', {
      is: true,
      then: yup.string().required(i18nARError('event.budget_code', 'blank')),
    }),
  costCenters: yup
    .string()
    .nullable()
    .when('costCenterRequired', {
      is: true,
      then: yup.string().required(i18nARError('event.cost_centers', 'blank')),
    }),
});

export function Provider({
  baseURL,
  hotelsURL,
  eventData,
  children,
  isMeetingRequestForm,
}) {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  axios.defaults.headers.common['X-CSRF-Token'] = token;

  const axiosInstance = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
  });

  const formattedDate = (date) => (date && date.format('YYYY-MM-DD')) || '';

  const meetingStartDate = eventData.meeting_start_date && moment(eventData.meeting_start_date);
  const eventStartDate = eventData.startDate && moment(eventData.startDate);
  const meetingEndDate = eventData.meeting_end_date && moment(eventData.meeting_end_date);
  const eventEndDate = eventData.endDate && moment(eventData.endDate);
  const roomNights = (eventStartDate && eventEndDate) && eventEndDate.diff(eventStartDate, 'days');

  const defaultData = {
    name: eventData.name,

    // MeetingRoom Defaults
    meetingStartDate: formattedDate(meetingStartDate || eventStartDate),
    meetingStartTime: eventData.meeting_start_time || eventData.start_time || '7:00',
    meetingEndDate: formattedDate(meetingEndDate || eventEndDate),
    meetingEndTime: eventData.meeting_end_time || eventData.end_time || '18:00',
    attendeesNum: eventData.meeting_attendees_number || eventData.max_attendees || 0,
    setup: eventData.setup,
    meetingRoomNights: eventData.meeting_room_days || [],
    isMeetingRoomsCustomized: eventData.meeting_rooms_customized || false,
    sleepingRoomNights: eventData.sleeping_room_nights,
    maxMeetingAttendees: eventData.max_attendees,

    // SleepingRoom Defaults
    checkIn: formattedDate(eventStartDate),
    checkOut: formattedDate(eventEndDate),
    roomNights: roomNights || 0,
    roomSingle: eventData.single_room_count || 0,
    roomDouble: eventData.double_room_count || 0,
    roomSuite: eventData.suites_count || 0,
    maxSleepingRooms: eventData.sleeping_rooms,
    isSleepingRoomsCustomized: eventData.sleeping_rooms_customized || false,
    methodOfReservation: eventData.method_of_reservation || 'rooming_list',
    paidBy: eventData.paid_by || 'master',

    // AudioVisual Defaults
    avOptions: eventData.selected_av_options,
    customAvOptions: eventData.custom_av_options,

    // FoodBeverage Defaults
    fbOptions: eventData.selected_fb_options,
    fnbCustomData: eventData.custom_fb_options,

    // Attachments
    attachments: eventData.attachments,

    // Venues
    selectedVenues: eventData.selectedVenues,
    // Other Request Data
    answerBy: eventData.answer_by,
    datesFlexible: eventData.dates_flexible ? 'yes' : 'no',
    datesFlexibleDetails: eventData.dates_flexible_details,
    about: eventData.additional_event_requirements,

    // Custom questions
    questions: eventData.questions || [],

    // Budget Code & Cost Center
    budgetCode: eventData.budgetCode || '',
    costCenters: eventData.costCenters || '',
    budgetCodeRequired: eventData.budgetCodeRequired,
    costCenterRequired: eventData.costCenterRequired,
    departmentId: eventData.department_id,
    activeDepartments: eventData.active_departments,
  };

  const defaultFields = {};
  const defaultSchema = yup.object().shape(defaultFields);

  const [fields, setFields] = useState(defaultData);
  const [validationError, setValidationError] = useState([]);
  const [usedFields, setUsedFields] = useState([]);
  const [questionsValidateSchema, setQuestionsValidateSchema] = useState(defaultSchema);
  const [questionsValidationError, setQuestionsValidationError] = useState([]);

  // ====================================
  // Validation

  const handleFormValidation = (formFields) => {
    schema
      .validate(formFields, { abortEarly: false })
      .then(
        () => {
          setValidationError([]);
        },
        (response) => {
          setValidationError(response.inner);
        },
      );

    const questions = formFields.questions || [];
    const questionValues = {};
    questions.forEach((q) => {
      questionValues[q.id] = q.response;
    });

    questionsValidateSchema
      .validate(questionValues, { abortEarly: false })
      .then(
        () => {
          setQuestionsValidationError([]);
        },
        (response) => {
          setQuestionsValidationError(response.inner);
        },
      );
  };

  useEffect(() => {
    handleFormValidation({ ...fields });
  }, [fields]);

  // ====================================
  // Validation (Questions)

  const questionsStr = JSON.stringify(eventData.questions);

  useEffect(() => {
    const questions = eventData.questions || [];
    const allFields = questions.map((f) => {
      let vType = '';
      switch (f.questionType) {
        case 'numeric':
        case 'pricefield':
          vType = 'number';
          break;
        case 'select_currency':
        case 'date':
        case 'currency':
        case 'text':
        case 'textarea':
        case 'textfield':
        default:
          vType = 'string';
          break;
      }
      return ({
        ...f,
        name: f.id,
        validationType: vType,
      });
    });

    const yepSchema = allFields.reduce(createYupSchema, {});
    const vSchema = yup.object().shape(yepSchema);

    setQuestionsValidateSchema(vSchema);
  }, [questionsStr]);

  // ====================================
  // General Actions

  const handleUpdateValue = (name, value) => {
    const newData = {};
    newData[name] = value;
    setFields({ ...fields, ...newData });
  };

  const handleUpdateFields = ({ onSuccess, onError }) => {
    const { id: bidId, ...fieldsToUpdate } = fields;
    axiosInstance.patch(`/${bidId}`, { bid_content: fieldsToUpdate })
      .then(() => {
        if (onSuccess && typeof (onSuccess) === 'function') {
          onSuccess();
        }
      })
      .catch((error) => {
        if (onError && typeof (onError) === 'function') {
          onError(error);
        }
      });
  };

  const handleTellUs = (data) => {
    const { about } = data;
    axiosInstance.post('/event_requirements', {
      bid_request: {
        additional_event_requirements: about,
      },
    })
      .then((data) => {
        setFields({ ...fields, about: data.data.about });
      })
      .catch((error) => {
        // TODO: Catch error
        console.log(error);
      });
  };

  const dataToSave = {
    name: fields.name,
    additional_event_requirements: fields.about,
    answer_by: fields.answerBy,
    meeting_attendees_number: fields.attendeesNum,
    meeting_start_date: fields.meetingStartDate,
    meeting_start_time: fields.meetingStartTime,
    meeting_end_date: fields.meetingEndDate,
    meeting_end_time: fields.meetingEndTime,
    checkin_date: fields.checkIn,
    checkout_date: fields.checkOut,
    dates_flexible: fields.datesFlexible,
    double_rooms: fields.roomDouble,
    single_rooms: fields.roomSingle,
    suite_rooms: fields.roomSuite,
    customized_rooms: fields.isSleepingRoomsCustomized,
    room_nights: fields.roomNights,
    room_setup: fields.setup,
    audio_menus: fields.avOptions,
    food_menus: fields.fbOptions,
    attachments: fields.attachments,
    meeting_room_days: fields.meetingRoomNights,
    sleeping_room_nights: fields.sleepingRoomNights,
    food_requirements: fields.fnbCustomData,
    av_requirements: fields.customAvOptions,
    questions: fields.questions,
    budget_code: fields.budgetCode,
    cost_centers: fields.costCenters,
    department_id: fields.departmentId,
    paid_by: fields.paidBy,
    method_of_reservation: fields.methodOfReservation,
  };

  if (fields.datesFlexible === 'yes') {
    dataToSave.dates_flexible_details = fields.datesFlexibleDetails;
  }

  const handleRequestNow = () => {
    const path = isMeetingRequestForm === true ? '?continue_event_request_form=true' : '/';

    axiosInstance.put(path, {
      ...dataToSave,
      commit: 'Request',
    })
      .then((data) => {
        window.location.href = data.data.redirect_url;
      })
      .catch((error) => {
        // TODO: Catch error
        console.log(error);
      });
  };

  const updateUsedFields = (name) => {
    if (!usedFields.includes(name)) {
      usedFields.push(name);
      setUsedFields(usedFields);
      handleFormValidation(fields);
    }
  };

  const hasErrorMessage = (name) => {
    const messages = [];
    validationError.forEach((e) => {
      if ((e.path === name) && (usedFields.includes(name))) {
        messages.push(e.message);
      }
    });
    questionsValidationError.forEach((e) => {
      if ((e.path === name) && (usedFields.includes(name))) {
        messages.push(e.message);
      }
    });
    return messages;
  };

  const handleSaveDraft = () => {
    axiosInstance.put('?send_mail=false&draft=true', {
      ...dataToSave,
      commit: 'Save',
    })
      .then((data) => {
        window.location.href = data.data.redirect_url;
      })
      .catch((error) => {
        // TODO: Catch error
        console.log(error);
      });
  };

  const addMoreHotels = () => {
    window.location.href = hotelsURL;
  };

  const sendBidstoAllHotels = () => {
    axiosInstance.put('?send_mail=true', {
      ...dataToSave,
      commit: 'Request',
    })
      .then((data) => {
        if (data.data.missing_expert) {
          window.showModal('#missing_expert_modal');
        } else if (data.data.redirect_url) {
          window.location.href = data.data.redirect_url;
        }
      })
      .catch((error) => {
        // TODO: Catch error
        console.log(error);
      });
  };

  const contextData = {
    formData: fields,
    setField: handleUpdateValue,
    setFormData: setFields,
    handleSaveDraft,
    handleRequestNow,
    saveData: handleUpdateFields,
    handleTellUs,
    addMoreHotels,
    sendBidstoAllHotels,
    updateUsedFields,
    hasErrorMessage,
    formErrors: validationError.concat(questionsValidationError),
  };

  return (
    <Context.Provider value={contextData}>
      {children}
    </Context.Provider>
  );
}

export const { Consumer } = Context;

Provider.defaultProps = {
  isMeetingRequestForm: false,
};

Provider.propTypes = {
  baseURL: PropTypes.string.isRequired,
  hotelsURL: PropTypes.string.isRequired,
  eventData: PropTypes.shape().isRequired,
  isMeetingRequestForm: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
