import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FiSettings } from 'react-icons/fi';
import { FaRegCalendarAlt } from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import moment from 'moment';
import RadioButtons from '../../../Common/Form/RadioButton/RadioButtons';
import FilePicker from '../../../Common/Form/FilePicker/FilePicker';
import TextField from '../../../Common/Form/TextField';
import SelectField from '../../../Common/Form/SelectField/SelectField';
import QuestionsForm from './QuestionsForm';
import { FlexFlow, PageSection, PageSectionTitle } from '../../../Common/helpers/DisplayHelpers';
import DateField from '../../../Common/Form/DateField/DateField';
import { EventRequirementsContext } from '../context';
import { i18n, i18nARAttr } from '../../../../src/custom/Internationalization';

function AdditionalForm({
  id,
  filepickerOptions,
  isReadOnly,
}) {
  const sidebarDataContext = useContext(EventRequirementsContext);
  const {
    formData,
    formData: {
      budgetCode,
      costCenters,
      departmentId,
      activeDepartments,
      costCenterRequired,
      budgetCodeRequired,
      questions,
    },
    setField,
    updateUsedFields,
    hasErrorMessage,
  } = sidebarDataContext;

  const handleUpdate = (field, value) => {
    setField(field, value);
  };

  const debouncedUpdate = _.debounce(handleUpdate, 500);

  return (
    <PageSection id={id}>
      <PageSectionTitle>
        <FiSettings />
        {i18n.t('event_request_form.headings.additional_details')}
      </PageSectionTitle>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FlexFlow column splitSpace="1em">
            <RadioButtons
              isDisabled={isReadOnly}
              variant="inline"
              id="event_dates_flexible"
              name="event_dates_flexible"
              label={i18nARAttr('bid_request.dates_flexible')}
              value={formData.datesFlexible}
              items={[
                { label: i18n.t('actions.true'), value: 'yes' },
                { label: i18n.t('actions.false'), value: 'no' },
              ]}
              onChange={(v) => setField('datesFlexible', v)}
            />
          </FlexFlow>
        </Grid>
      </Grid>

      {formData.datesFlexible === 'yes' && (
        <Grid container spacing={3} className="tw-mt-4">
          <Grid item xs={12} sm={12}>
            <FlexFlow column splitSpace="1em">
              <TextField
                size="small"
                label={i18nARAttr('bid_request.dates_flexible_details')}
                id="event_dates_flexible_details"
                name="event_dates_flexible_details"
                value={formData.datesFlexibleDetails || ''}
                onChange={(e) => debouncedUpdate('datesFlexibleDetails', e.target.value)}
                flexGrow
                nomargin
                minheight="30px"
                variant="outlined"
                className="tw-mt-4"
                disabled={isReadOnly}
              />
            </FlexFlow>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3} className="tw-mt-4">
        <Grid item xs={12} sm={6}>
          <FlexFlow column splitSpace="1em">
            <DateField
              isDisabled={isReadOnly}
              variant="inline"
              noMargin
              id="event_answer_by"
              minDate={moment().format()}
              label={i18nARAttr('bid_request.answer_by')}
              value={formData.answerBy}
              onDateSelected={(v) => setField('answerBy', v)}
              startAdornment={<FaRegCalendarAlt />}
            />
          </FlexFlow>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="tw-mt-4">
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            size="small"
            label={i18nARAttr('event.budget_code')}
            id={`${id}-budget-code`}
            value={budgetCode || ''}
            onBlur={() => updateUsedFields('budgetCode')}
            onChange={(e) => debouncedUpdate('budgetCode', e.target.value)}
            helpertext={hasErrorMessage('budgetCode').length > 0 ? hasErrorMessage('budgetCode').join(', ') : ''}
            error={hasErrorMessage('budgetCode').length > 0}
            flexGrow
            nomargin
            minheight="30px"
            variant="outlined"
            isRequired={budgetCodeRequired}
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            size="small"
            label={i18nARAttr('event.cost_centers')}
            id={`${id}-cost-centers`}
            value={costCenters || ''}
            onBlur={() => updateUsedFields('costCenters')}
            onChange={(e) => debouncedUpdate('costCenters', e.target.value)}
            helpertext={hasErrorMessage('costCenters').length > 0 ? hasErrorMessage('costCenters').join(', ') : ''}
            error={hasErrorMessage('costCenters').length > 0}
            flexGrow
            nomargin
            minheight="30px"
            isRequired={costCenterRequired}
            variant="outlined"
            className="tw-mt-4"
            disabled={isReadOnly}
          />
        </Grid>
        {activeDepartments && activeDepartments.length > 0 && (
          <Grid item xs={12} sm={6} md={6}>
            <SelectField
              small
              disabled={isReadOnly}
              borderRadius="0"
              minheight="20px"
              id="select"
              name="select"
              label={i18nARAttr('event.department_id')}
              value={departmentId || ''}
              defaultValue={null}
              items={activeDepartments}
              onChange={(v) => setField('departmentId', v)}
              error={hasErrorMessage('selectDepartment').length > 0}
              helperText={hasErrorMessage('selectDepartment').length > 0 ? hasErrorMessage('selectDepartment')
                .join(', ') : ''}
            />
          </Grid>
        )}
      </Grid>

      {questions && questions.length > 0 && (
        <QuestionsForm
          isReadOnly={isReadOnly}
          filepickerOptions={filepickerOptions}
        />
      )}

      <FlexFlow padding="1em 0 0">
        <FilePicker
          {...filepickerOptions}
          isDisabled={isReadOnly}
          inputData={{
            id: 'number',
            label: i18n.t('event_request_form.headings.attached_files'),
            value: formData.attachments,
          }}
          onSubmit={(files) => setField('attachments', files || [])}
          btnAddName={i18n.t('actions.attach_files').toUpperCase()}
          btnRemoveName={i18n.t('actions.remove_all')}
          maxFiles={10}
          variant="left"
        />
      </FlexFlow>
    </PageSection>
  );
}

AdditionalForm.defaultProps = {
  id: '',
  isReadOnly: false,
  activeDepartments: [],
};

AdditionalForm.propTypes = {
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  filepickerOptions: PropTypes.shape().isRequired,
  activeDepartments: PropTypes.arrayOf(PropTypes.object),
};

export default AdditionalForm;
