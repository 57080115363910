import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import { IoIosBed } from 'react-icons/io';
import {
  FaRegHandshake,
} from 'react-icons/fa';
import {
  FiGrid,
  FiCoffee,
  FiMonitor,
  FiChevronLeft,
} from 'react-icons/fi';
import moment from 'moment';
import Panel from '../../Common/Panel';
import {
  FlexFlow,
  PageWrapper,
  PageAside,
  PageContent,
  StickyArea,
  Placeholder,
} from '../../Common/helpers/DisplayHelpers';
import AboutForm from './content/AboutForm';
import MeetingRoomForm from './content/MeetingRoomForm';
import AudioVisualForm from './content/AudioVisualForm';
import FoodBeverageForm from './content/FoodBeverageForm';
import SleepingRoomForm from './content/SleepingRoomForm';
import AdditionalForm from './content/AdditionalForm';
import NotificationMessage from './content/NotificationMessage';
import { EventRequirementsContext, EventRequirementsContextProvider } from './context';
import EventRequirementsFooter from './content/EventRequirementsFooter';
import {
  ToggleIcon,
  PanelTitle,
  PanelText,
} from './styles';
import { i18n, i18nARAttr } from '../../../src/custom/Internationalization';

function EventRequirementsPage({
  baseUrl,
  hotelsUrl,
  eventData,
  eventData: {
    name,
    startDate,
    endDate,
    type,
    avOptions,
    fbOptions,
    questions,
  },
  filepickerOptions,
  selectedVenues,
  selectedVenueCount,
  ruleOptions: {
    hasSleepingRooms,
    hasMeetingRooms,
    hasExpertAssist,
    isNew,
    isReadOnly,
    isDraft,
    hasNewVenues,
  },
  currentData,
  scrollClass,
  isSidebarClosed,
  isMeetingRequestForm,
  showVenueFooter,
  existingBid,
}) {
  const [isSidebarOpen, setSidebarOpen] = useState(!isSidebarClosed);
  const dataContext = useContext(EventRequirementsContext);
  const {
    handleTellUs,
  } = dataContext;

  const stickyOffset = existingBid ? '45px' : '0%';
  return (
    <React.Suspense fallback={i18n.t('actions.loading')}>
      <PageWrapper>
        <PageContent bgColor="white">
          <EventRequirementsContextProvider
            baseURL={baseUrl}
            hotelsURL={hotelsUrl}
            eventData={eventData}
            currentData={currentData}
            isMeetingRequestForm={isMeetingRequestForm}
          >
            <FlexFlow grow shrink nowrap>
              <Placeholder w="90px" />
              <PageAside isClosed={!isSidebarOpen}>
                <StickyArea>
                  <FlexFlow column padding="1em 0 0">
                    <ToggleIcon
                      isClosed={!isSidebarOpen}
                      onClick={() => setSidebarOpen(!isSidebarOpen)}
                    >
                      <FiChevronLeft />
                    </ToggleIcon>
                  </FlexFlow>

                  <Panel
                    className={isSidebarOpen ? '' : 'hidden'}
                  >
                    <PanelTitle>
                      {i18nARAttr('event.name')}
                    </PanelTitle>
                    <PanelText>
                      {name}
                    </PanelText>
                    <PanelTitle>
                      {i18nARAttr('event.start_date')}
                    </PanelTitle>
                    <PanelText>
                      {moment(startDate).format('dddd, MMMM DD, YYYY')}
                    </PanelText>
                    <PanelTitle>
                      {i18nARAttr('event.end_date')}
                    </PanelTitle>
                    <PanelText>
                      {moment(endDate).format('dddd, MMMM DD, YYYY')}
                    </PanelText>
                    <PanelTitle>
                      {i18nARAttr('event.event_type')}
                    </PanelTitle>
                    <PanelText>
                      {type}
                    </PanelText>
                  </Panel>

                  <Scrollspy
                    items={['meeting', 'audiovisual', 'foodbeverage', 'sleeping', 'additional']}
                    currentClassName="active"
                    className={`scrollSpy ${isSidebarOpen ? '' : 'closed'}`}
                    rootEl={scrollClass}
                  >
                    {hasMeetingRooms && (
                      <li>
                        <a href="#meeting">
                          <FaRegHandshake />
                          <span className="text">
                            {i18n.t('navigation.event_request_form.meeting_room_requirements.name')}
                          </span>
                        </a>
                      </li>
                    )}
                    {hasMeetingRooms && (
                      <li>
                        <a href="#audiovisual">
                          <FiMonitor />
                          <span className="text">
                            {i18n.t('navigation.event_request_form.audio_visual.name')}
                          </span>
                        </a>
                      </li>
                    )}
                    {hasMeetingRooms && (
                      <li>
                        <a href="#foodbeverage">
                          <FiCoffee />
                          <span className="text">
                            {i18n.t('navigation.event_request_form.food_and_beverage.name')}
                          </span>
                        </a>
                      </li>
                    )}
                    {hasSleepingRooms && (
                      <li>
                        <a href="#sleeping">
                          <IoIosBed />
                          <span className="text">
                            {i18n.t('navigation.event_request_form.sleeping_rooms.name')}
                          </span>
                        </a>
                      </li>
                    )}
                    <li>
                      <a href="#additional">
                        <FiGrid />
                        <span className="text">
                          {i18n.t('navigation.event_request_form.additional_details.name')}
                        </span>
                      </a>
                    </li>
                  </Scrollspy>
                </StickyArea>
              </PageAside>

              <PageContent padded maxwidth="960px">
                {isReadOnly && hasExpertAssist && (
                  <NotificationMessage>
                    <FlexFlow nowrap align="center" splitSpace="1em">
                      <p>
                        {i18n.t('event_request_form.notices.not_editable')}
                      </p>
                    </FlexFlow>
                  </NotificationMessage>
                )}

                {isReadOnly && !hasExpertAssist && (
                  <NotificationMessage>
                    <FlexFlow nowrap align="center" splitSpace="1em">
                      <p>
                        {i18n.t('event_request_form.notices.submitted')}
                      </p>
                    </FlexFlow>
                  </NotificationMessage>
                )}

                <AboutForm
                  id="about"
                  isReadOnly={isReadOnly}
                  onTellUs={handleTellUs}
                />

                {hasMeetingRooms && (
                  <MeetingRoomForm
                    id="meeting"
                    isReadOnly={isReadOnly}
                  />
                )}

                {hasMeetingRooms && (
                  <AudioVisualForm
                    id="audiovisual"
                    allAVOptions={avOptions}
                    event={eventData}
                    isReadOnly={isReadOnly}
                  />
                )}

                {hasMeetingRooms && (
                  <FoodBeverageForm
                    id="foodbeverage"
                    allFBOptions={fbOptions}
                    event={eventData}
                    isReadOnly={isReadOnly}
                  />
                )}

                {hasSleepingRooms && (
                  <SleepingRoomForm
                    id="sleeping"
                    isReadOnly={isReadOnly}
                  />
                )}
                <AdditionalForm
                  id="additional"
                  isReadOnly={isReadOnly}
                  filepickerOptions={filepickerOptions}
                  questions={questions}
                />
              </PageContent>
            </FlexFlow>

            <StickyArea bottom={stickyOffset}>
              <EventRequirementsFooter
                venues={selectedVenues}
                venueCount={selectedVenueCount}
                hasExpertAssist={hasExpertAssist}
                isNew={isNew}
                hotelsUrl={hotelsUrl}
                isReadOnly={isReadOnly}
                isDraft={isDraft}
                hasNewVenues={hasNewVenues}
                isMeetingRequestForm
                showVenueFooter={showVenueFooter}
              />
            </StickyArea>
          </EventRequirementsContextProvider>
        </PageContent>
      </PageWrapper>
    </React.Suspense>
  );
}

EventRequirementsPage.defaultProps = {
  scrollClass: '',
  currentData: {},
  hotelsUrl: '',
  isSidebarClosed: false,
  isMeetingRequestForm: false,
  showVenueFooter: true,
  existingBid: false,
};

EventRequirementsPage.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  hotelsUrl: PropTypes.string,
  eventData: PropTypes.shape().isRequired,
  filepickerOptions: PropTypes.shape().isRequired,
  selectedVenues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedVenueCount: PropTypes.number.isRequired,
  ruleOptions: PropTypes.shape().isRequired,
  scrollClass: PropTypes.string,
  currentData: PropTypes.shape(),
  isSidebarClosed: PropTypes.bool,
  isMeetingRequestForm: PropTypes.bool,
  showVenueFooter: PropTypes.bool,
  existingBid: PropTypes.bool,
};

export default EventRequirementsPage;
