/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { FlexFlow } from '../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../Common/helpers/styles';
import { i18n } from '../../../../src/custom/Internationalization';

function AddHotelsModal({ isOpen, onCancel, onConfirm }) {
  const handleConfirm = () => onConfirm();
  const handleCancel = () => onCancel();

  return (
    <ConfirmationDialog
      open={isOpen}
      title={i18n.t('event_request_form.headings.add_venues')}
      maxWidth="sm"
      showTitle={false}
      showActionBtns={false}
      handleCancel={onCancel}
      handleConfirm={onConfirm}
    >
      <FlexFlow padding="1.5rem" nowrap>
        <div className="add_hotels__image" />

        <FlexFlow column shrink padding="1em 1em 1em 3em">
          <FlexFlow column shrink padding="0 0 2em">
            <h3>{i18n.t('event_request_form.headings.add_venues')}</h3>
            <h4>{i18n.t('event_request_form.messages.add_venues')}</h4>
          </FlexFlow>

          <FlexFlow
            splitSpace="1em"
            padding="1em"
          >
            <GroupizeBtn large="true" variant="contained" onClick={handleConfirm}>
              {i18n.t('actions.got_it')}
            </GroupizeBtn>
            <GroupizeBtn large="true" onClick={handleCancel}>
              {i18n.t('actions.cancel')}
            </GroupizeBtn>
          </FlexFlow>
        </FlexFlow>
      </FlexFlow>
    </ConfirmationDialog>
  );
}

AddHotelsModal.defaultProps = {
  isOpen: false,
};

AddHotelsModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AddHotelsModal;
