import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import {
  FiChevronRight,
  FiMail,
  FiSave,
  FiEye,
  FiPlus,
  FiSend,
  FiCheckSquare,
} from 'react-icons/fi';

import SimpleTabs from '../../../../Common/StyledTabs/SimpleTabs';
import {
  FlexFlow, PageContent, PageFooter, PageText,
} from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';

import { EventRequirementsContext } from '../../context';
import {
  GroupizeIcon,
  NotificationIcon,
  FooterContentWrapper,
  FooterContentScrollable,
} from '../../styles';
import HotelItem from './HotelItem';
import AddHotelsModal from '../AddHotelsModal';
import { i18n } from '../../../../../src/custom/Internationalization';

const ERFPageFooter = styled(PageFooter)`
  padding-bottom: 0;
`;

function EventRequirementsFooter({
  venues,
  venueCount,
  isNew,
  hasExpertAssist,
  isReadOnly,
  isDraft,
  hasNewVenues,
  isMeetingRequestForm,
  showVenueFooter,
}) {
  const dataContext = useContext(EventRequirementsContext);
  const {
    handleRequestNow,
    handleSaveDraft,
    addMoreHotels,
    sendBidstoAllHotels,
    formErrors,
  } = dataContext;

  const [showHotelsModal, setShowHotelsModal] = useState(false);
  const [showVenues, setShowVenues] = useState(false);
  const [tab, setTab] = useState(0);

  const newFooterActions = [
    {
      actionId: 'save_draft',
      variant: 'outlined',
      btnName: i18n.t('event_request_form.actions.save_draft'),
      action: handleSaveDraft,
      iconBefore: <FiSave />,
      iconAfter: null,
    },
    {
      actionId: 'send_to_expert',
      variant: 'contained',
      btnName: hasExpertAssist ? i18n.t('event_request_form.actions.send_to_expert') : i18n.t('event_request_form.actions.request_now'),
      action: handleRequestNow,
      iconBefore: <FiCheckSquare />,
      iconAfter: <FiChevronRight />,
    },
  ];

  const buttonLabel = hasNewVenues ? 'send_bid_to_new_venues' : 'send_bid_to_all_venues';

  const editFooterActions = [
    {
      actionId: 'save_draft',
      variant: 'outlined',
      btnName: i18n.t('event_request_form.actions.save_draft'),
      action: handleSaveDraft,
      iconBefore: <FiSave />,
      iconAfter: null,
      isHidden: isReadOnly,
    },
    {
      actionId: 'add_more_venues',
      variant: 'outlined',
      btnName: i18n.t('event_request_form.actions.add_more_venues'),
      action: () => setShowHotelsModal(true),
      iconBefore: <FiPlus />,
      iconAfter: null,
      isHidden: hasExpertAssist && isReadOnly,
    },
    {
      actionId: 'send_bid',
      variant: 'contained',
      btnName: i18n.t(`event_request_form.actions.${buttonLabel}`),
      action: sendBidstoAllHotels,
      iconBefore: <FiSend />,
      iconAfter: <FiChevronRight />,
      isHidden: hasExpertAssist || (venueCount === 0) || (isReadOnly && !hasNewVenues),
    },
    {
      actionId: 'send_to_expert',
      variant: 'contained',
      btnName: i18n.t('event_request_form.actions.send_to_expert'),
      action: handleRequestNow,
      iconBefore: <FiCheckSquare />,
      iconAfter: <FiChevronRight />,
      isHidden: !(hasExpertAssist && isDraft),
    },
  ];

  const footerActions = isNew ? newFooterActions : editFooterActions;

  const handleViewVenuesClick = () => {
    setShowVenues(!showVenues);
  };

  return (
    <ERFPageFooter shadow>
      <FlexFlow nowrap>
        {isMeetingRequestForm !== true
          && (
            <PageContent padded borderRight noGrow>
              <FlexFlow grow align="center" splitSpace="0.5em">
                <NotificationIcon>
                  <FiMail />
                  <span className="num">
                    {venueCount}
                  </span>
                </NotificationIcon>
                <Hidden smDown>
                  <FlexFlow grow align="center" splitSpace="0.5em">
                    <PageText nomargin>
                      {i18n.t('event_request_form.messages.request_bids')}
                    </PageText>
                    <GroupizeIcon>
                      <FiChevronRight />
                    </GroupizeIcon>
                    <PageText nomargin>
                      <span>{venueCount}</span>
                      {' '}
                      {i18n.t('event_request_form.messages.venues_added')}
                    </PageText>
                  </FlexFlow>
                </Hidden>
              </FlexFlow>
            </PageContent>
          )}
        <PageContent padded>
          <FlexFlow
            splitSpace="0.5em"
            justify="space-between"
          >
            <FlexFlow>
              {showVenueFooter && (
                <GroupizeBtn
                  size="small"
                  variant="outlined"
                  onClick={handleViewVenuesClick}
                >
                  <FlexFlow align="center" splitSpace="0.5em">
                    <FiEye />
                    <Hidden xsDown>
                      <span>
                        {showVenues ? i18n.t('event_request_form.actions.hide_venues') : i18n.t('event_request_form.actions.view_venues')}
                      </span>
                    </Hidden>
                  </FlexFlow>
                </GroupizeBtn>
              )}
            </FlexFlow>

            <FlexFlow
              splitSpace="0.5em"
              justify="flex-end"
              margin="0 0 0 auto"
            >
              {footerActions.map(({
                actionId,
                btnName,
                action,
                variant,
                iconBefore,
                iconAfter,
                isHidden,
              }) => (
                <Fragment key={actionId}>
                  {!isHidden && (
                    <GroupizeBtn
                      variant={variant}
                      onClick={action}
                      disabled={formErrors.length > 0}
                      key={btnName}
                    >
                      <FlexFlow align="center" splitSpace="0.5em">
                        {iconBefore}
                        <Hidden smDown>
                          <span>
                            {btnName}
                          </span>
                        </Hidden>
                        {iconAfter}
                      </FlexFlow>
                    </GroupizeBtn>
                  )}
                </Fragment>
              ))}
            </FlexFlow>
          </FlexFlow>
        </PageContent>
      </FlexFlow>

      {showVenues && (
        <PageContent borderTop>
          <FooterContentWrapper>
            <FooterContentScrollable>
              <PageContent padded>
                <SimpleTabs
                  value={tab}
                  onChange={(e, v) => setTab(v)}
                  variant="scrollable"
                  scrollButtons="auto"
                  items={venues.map(({ address }) => (
                    { notification: 0, label: address }
                  ))}
                />
                <FlexFlow column padding="2em 1em">
                  {venues.map(({ hotels }, i) => (
                    tab === i && hotels && hotels.map((property) => (
                      <HotelItem key={property.id} hotel={property} />
                    ))
                  ))}
                </FlexFlow>
              </PageContent>
            </FooterContentScrollable>
          </FooterContentWrapper>
        </PageContent>
      )}

      <AddHotelsModal
        isOpen={showHotelsModal}
        onConfirm={() => {
          addMoreHotels();
          setShowHotelsModal(false);
        }}
        onCancel={() => setShowHotelsModal(false)}
      />
    </ERFPageFooter>
  );
}

EventRequirementsFooter.defaultProps = {
  isNew: false,
  venueCount: 0,
  isReadOnly: false,
  isDraft: false,
  hasNewVenues: false,
  isMeetingRequestForm: false,
  showVenueFooter: true,
};

EventRequirementsFooter.propTypes = {
  venues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hasExpertAssist: PropTypes.bool.isRequired,
  isNew: PropTypes.bool,
  hasNewVenues: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isDraft: PropTypes.bool,
  isMeetingRequestForm: PropTypes.bool,
  venueCount: PropTypes.number,
  showVenueFooter: PropTypes.bool,
};

export default EventRequirementsFooter;
