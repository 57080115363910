/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  IoIosBed,
} from 'react-icons/io';
import {
  FaRegCalendarAlt,
  FaRegStar,
  FaPencilAlt,
  FaConciergeBell,
} from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import FullscreenDialog from '../../../../Dialogs/FullScreenDialog';
import DataPair from '../../../../Common/DataPair';
import Panel from '../../../../Common/Panel';
import {
  FlexFlow, PageText, PageSection, PageSectionTitle,
} from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';
import NumberField from '../../../../Common/Form/NumberField';
import DateField from '../../../../Common/Form/DateField/DateField';
import RadioButtons from '../../../../Common/Form/RadioButton/RadioButtons';

import { EventRequirementsContext } from '../../context';
import { GroupizeIcon } from '../../styles';
import { i18n, i18nARAttr } from '../../../../../src/custom/Internationalization';

import SleepingRoomsModal from './SleepingRoomsModal';

function SleepingRoomForm({
  id,
  isReadOnly,
}) {
  const sidebarDataContext = useContext(EventRequirementsContext);
  const {
    formData,
    setFormData,
    setField,
    formErrors,
  } = sidebarDataContext;

  const [isDetailsModalOpen, toggleDetailsModal] = useState(false);
  const isCustomized = formData.isSleepingRoomsCustomized;

  const calculateRooms = ({
    roomSingle = 0,
    roomDouble = 0,
    roomSuite = 0,
  }) => (
    +roomSingle + +roomDouble + +roomSuite
  );

  const hasErrorMessage = (name) => {
    const messages = [];
    formErrors.forEach((e) => {
      if (e.path === name) {
        messages.push(e.message);
      }
    });
    return messages;
  };

  const setDateField = (name, value) => {
    const { checkIn, checkOut } = formData;

    const getNightsAmount = (start, end) => {
      const startDate = moment(start);
      const endDate = moment(end);
      return Math.abs(endDate.diff(startDate, 'days'));
    };

    const newCheckIn = name === 'checkIn' ? value : checkIn;
    const newCheckOut = name === 'checkOut' ? value : checkOut;
    const newNightsAmount = (newCheckIn && newCheckOut)
      ? getNightsAmount(newCheckIn, newCheckOut)
      : 0;

    setFormData({
      ...formData,
      checkIn: newCheckIn,
      checkOut: newCheckOut,
      roomNights: newNightsAmount,
    });
  };

  useEffect(() => {
    const currentDay = moment(formData.checkIn);
    if (currentDay.isAfter(formData.checkOut) || !moment(formData.checkOut).isValid()) {
      currentDay.add(1, 'd');
      setDateField('checkOut', currentDay);
    }
  }, [formData.checkIn]);

  const sleepingRoomNights = formData.sleepingRoomNights || [];

  return (
    <PageSection id={id}>
      <PageSectionTitle>
        <IoIosBed />
        {i18n.t('event_request_form.headings.sleeping_rooms')}
      </PageSectionTitle>

      {isCustomized && sleepingRoomNights.map(({ date, rooms }) => (
        <Fragment key={date}>
          <PageSectionTitle>
            {moment(date).format('dddd, MMMM DD, YYYY')}
          </PageSectionTitle>

          <Panel>
            <PageText blueTitle />

            <FlexFlow splitSpace="2em">
              <DataPair
                title={`${i18nARAttr('bid_request_room_night.single_room_count')}:`}
                value={(
                  <FlexFlow nowrap align="center" splitSpace="0.5em">
                    <IoIosBed />
                    <span>{rooms.roomSingle || 0}</span>
                  </FlexFlow>
                )}
              />

              <DataPair
                title={`${i18nARAttr('bid_request_room_night.double_room_count')}:`}
                value={(
                  <FlexFlow nowrap align="center" splitSpace="0.5em">
                    <IoIosBed />
                    <IoIosBed />
                    <span>{rooms.roomDouble || 0}</span>
                  </FlexFlow>
                )}
              />

              <DataPair
                title={`${i18nARAttr('bid_request_room_night.suites_count')}:`}
                value={(
                  <FlexFlow nowrap align="center" splitSpace="0.5em">
                    <FaRegStar />
                    <span>{rooms.roomSuite || 0}</span>
                  </FlexFlow>
                )}
              />

              <DataPair
                title={`${i18nARAttr('bid_request_room_night.total')}:`}
                value={(
                  <FlexFlow nowrap align="center" splitSpace="0.5em">
                    <FaConciergeBell />
                    <span>{calculateRooms(rooms)}</span>
                  </FlexFlow>
                )}
              />
            </FlexFlow>
          </Panel>
        </Fragment>
      ))}

      {!isCustomized && (
        <>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateField
                isDisabled={isReadOnly}
                variant="inline"
                noMargin
                id="event_check_in"
                label={i18nARAttr('room_request.checkin')}
                value={formData.checkIn}
                minDate={moment().format()}
                onDateSelected={(v) => setDateField('checkIn', v)}
                minheight="40px"
                startAdornment={<FaRegCalendarAlt />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateField
                isDisabled={isReadOnly}
                variant="inline"
                noMargin
                id="event_check_out"
                label={i18nARAttr('room_request.checkout')}
                value={formData.checkOut}
                minDate={formData.checkIn || moment().format()}
                onDateSelected={(v) => setDateField('checkOut', v)}
                minheight="40px"
                startAdornment={<FaRegCalendarAlt />}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item>
              <RadioButtons
                isDisabled={isReadOnly}
                variant="inline"
                id="event_method_of_reservation"
                name="event_method_of_reservation"
                label={i18nARAttr('room_request.method_of_reservation')}
                value={formData.methodOfReservation}
                items={[
                  { label: i18n.t('room_request.method_options.rooming_list'), value: 'rooming_list' },
                  { label: i18n.t('room_request.method_options.individual'), value: 'individual' },
                ]}
                onChange={(v) => setField('methodOfReservation', v)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item>
              <RadioButtons
                isDisabled={isReadOnly}
                variant="inline"
                id="event_paid_by"
                name="event_paid_by"
                label={i18nARAttr('room_request.paid_by')}
                value={formData.paidBy}
                items={[
                  { label: i18n.t('room_request.paid_by_options.master'), value: 'master' },
                  { label: i18n.t('room_request.paid_by_options.individual'), value: 'individual' },
                ]}
                onChange={(v) => setField('paidBy', v)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <NumberField
                disabled={isReadOnly}
                variant="inline"
                nomargin
                id="room_single"
                label={i18nARAttr('bid_request_room_night.single_room_count')}
                flexGrow
                min={0}
                defaultNum={formData.roomSingle}
                onChanged={(value) => setField('roomSingle', value)}
                minheight="40px"
                startAdornment={<IoIosBed />}
                error={hasErrorMessage('roomSingle').length > 0}
                helperText={hasErrorMessage('roomSingle').length > 0 ? hasErrorMessage('roomSingle').join(', ') : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <NumberField
                disabled={isReadOnly}
                variant="inline"
                nomargin
                id="room_double"
                label={i18nARAttr('bid_request_room_night.double_room_count')}
                flexGrow
                min={0}
                defaultNum={formData.roomDouble}
                onChanged={(value) => setField('roomDouble', value)}
                minheight="40px"
                startAdornment={(
                  <>
                    <IoIosBed />
                    <IoIosBed />
                  </>
                )}
                error={hasErrorMessage('roomDouble').length > 0}
                helperText={hasErrorMessage('roomDouble').length > 0 ? hasErrorMessage('roomDouble').join(', ') : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <NumberField
                disabled={isReadOnly}
                variant="inline"
                nomargin
                id="room_suites"
                label={i18nARAttr('bid_request_room_night.suites_count')}
                flexGrow
                min={0}
                defaultNum={formData.roomSuite}
                onChanged={(value) => setField('roomSuite', value)}
                minheight="40px"
                startAdornment={<FaRegStar />}
                error={hasErrorMessage('roomSuite').length > 0}
                helperText={hasErrorMessage('roomSuite').length > 0 ? hasErrorMessage('roomSuite').join(', ') : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <NumberField
                disabled
                variant="inline"
                nomargin
                id="room_nights"
                label={i18nARAttr('bid_request_room_night.nights')}
                flexGrow
                defaultNum={formData.roomNights}
                min={0}
                onChanged={(value) => setField('roomNights', value)}
                minheight="40px"
                startAdornment={<FaRegCalendarAlt />}
              />
            </Grid>
          </Grid>
        </>
      )}

      {!isReadOnly && (
        <FlexFlow justify="flex-end">
          <GroupizeBtn onClick={() => toggleDetailsModal(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <GroupizeIcon wrapped>
                <FaPencilAlt />
              </GroupizeIcon>
              <span>{isCustomized ? i18n.t('actions.edit') : i18n.t('actions.customize')}</span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>
      )}

      <FullscreenDialog
        open={isDetailsModalOpen}
        handleClose={() => toggleDetailsModal(false)}
      >
        <SleepingRoomsModal
          handleClose={() => toggleDetailsModal(false)}
        />
      </FullscreenDialog>
    </PageSection>
  );
}

SleepingRoomForm.defaultProps = {
  id: '',
  isReadOnly: false,
};

SleepingRoomForm.propTypes = {
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

export default SleepingRoomForm;
