/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FiPaperclip } from 'react-icons/fi';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import cc from 'currency-codes';
import TextArea from '../../../Common/Form/TextArea';
import TextField from '../../../Common/Form/TextField';
import FilePicker from '../../../Common/Form/FilePicker/FilePicker';
import SelectField from '../../../Common/Form/SelectField/SelectField';
import { FlexFlow } from '../../../Common/helpers/DisplayHelpers';
import NumberField from '../../../Common/Form/NumberField';
import { EventRequirementsContext } from '../context';
import { i18n } from '../../../../src/custom/Internationalization';

const currencies = cc.codes();

function Questions({
  isReadOnly,
  filepickerOptions,
}) {
  const {
    formData: { questions = [] },
    setField,
    updateUsedFields,
    hasErrorMessage,
  } = useContext(EventRequirementsContext);

  const updateQuestion = (id, value) => {
    const updatedQuestions = questions.map((q) => (
      (q.id === id)
        ? { ...q, response: value }
        : { ...q }));
    setField('questions', updatedQuestions);
  };

  const debouncedUpdate = _.debounce(updateQuestion, 300);

  const displayField = ({
    id,
    content: label,
    questionType,
    response: value,
    required,
    options,
  }) => {
    switch (questionType) {
      case 'textarea':
        return (
          <TextArea
            id={`${id}`}
            label={label}
            placeholder={i18n.t('placeholders.please_enter')}
            value={value || ''}
            onChange={(v) => debouncedUpdate(id, v)}
            onBlur={() => updateUsedFields(id)}
            helpertext={hasErrorMessage(id).length > 0 ? hasErrorMessage(id).join(', ') : ''}
            error={hasErrorMessage(id).length > 0}
            nomargin
            minheight="50px"
            isRequired={required}
            disabled={isReadOnly}
          />
        );
      case 'textfield':
        return (
          <TextField
            size="small"
            variant="outlined"
            label={label}
            id={`${id}`}
            value={value || ''}
            onChange={(e) => debouncedUpdate(id, e.target.value)}
            onBlur={() => updateUsedFields(id)}
            helpertext={hasErrorMessage(id).length > 0 ? hasErrorMessage(id).join(', ') : ''}
            error={hasErrorMessage(id).length > 0}
            flexGrow
            nomargin
            minheight="30px"
            isRequired={required}
            disabled={isReadOnly}
          />
        );
      case 'select':
        return (
          <SelectField
            size="small"
            id={`${id}`}
            label={label}
            name={id}
            defaultValue={null}
            items={options}
            value={value}
            onChange={(val) => debouncedUpdate(id, val)}
            onBlur={() => updateUsedFields(id)}
            helpertext={hasErrorMessage(id).length > 0 ? hasErrorMessage(id).join(', ') : ''}
            error={hasErrorMessage(id).length > 0}
            flexGrow={1}
            isRequired={required}
            isDisabled={isReadOnly}
          />
        );
      case 'select_currency':
        return (
          <SelectField
            size="small"
            id={`${id}`}
            name={id}
            label={label}
            defaultValue={null}
            items={currencies.map((c) => ({ value: c, label: c }))}
            value={value}
            onChange={(val) => debouncedUpdate(id, val)}
            onBlur={() => updateUsedFields(id)}
            helpertext={hasErrorMessage(id).length > 0 ? hasErrorMessage(id).join(', ') : ''}
            error={hasErrorMessage(id).length > 0}
            flexGrow={1}
            isRequired={required}
            isDisabled={isReadOnly}
          />
        );
      case 'numberfield':
        return (
          <NumberField
            size="small"
            id={`${id}`}
            label={label}
            defaultNum={value}
            onChanged={(v) => updateQuestion(id, v)}
            onBlur={() => updateUsedFields(id)}
            helpertext={hasErrorMessage(id).length > 0 ? hasErrorMessage(id).join(', ') : ''}
            error={hasErrorMessage(id).length > 0}
            flexGrow
            nomargin
            minheight="30px"
            isRequired={required}
            disabled={isReadOnly}
          />
        );
      case 'pricefield':
        return (
          <NumberField
            priceCent
            size="small"
            id={`${id}`}
            label={label}
            defaultNum={value}
            onChanged={(v) => updateQuestion(id, v)}
            onBlur={() => updateUsedFields(id)}
            helpertext={hasErrorMessage(id).length > 0 ? hasErrorMessage(id).join(', ') : ''}
            error={hasErrorMessage(id).length > 0}
            flexGrow
            nomargin
            minheight="30px"
            isRequired={required}
            disabled={isReadOnly}
          />
        );
      case 'filepicker':
        return (
          <FilePicker
            {...filepickerOptions}
            maxFiles={5}
            id={`${id}`}
            isRequired={required}
            inputData={{
              label,
            }}
            onSubmit={(v) => debouncedUpdate(id, v)}
            variant="left"
            isDisabled={isReadOnly}
            btnAddName={(
              <FlexFlow align="center" splitSpace="1em">
                <FiPaperclip />
                <span>{i18n.t('headings.attachments')}</span>
              </FlexFlow>
            )}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <Grid container spacing={3} className="tw-mt-4">
      {questions.map((q) => (
        <Grid key={q.id} item xs={12} sm={6} md={6}>
          {displayField({ ...q, isReadOnly })}
        </Grid>
      ))}
    </Grid>
  );
}

Questions.defaultProps = {
  id: '',
  isReadOnly: false,
};

Questions.propTypes = {
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  filepickerOptions: PropTypes.shape().isRequired,
};

export default Questions;
