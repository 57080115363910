/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { FaRegHandshake } from 'react-icons/fa';
import {
  FiXCircle,
  FiChevronDown,
  FiPlus,
  FiTrash2,
} from 'react-icons/fi';
import Accordion from '../../../../Common/Accordion/Accordion';
import ConfirmationDialog from '../../../../Dialogs/ConfirmationDialog';
import { FlexFlow, PageSectionTitle } from '../../../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../../../Common/helpers/styles';

import AddDateModal from '../AddDateModal';
import RoomNightForm from './RoomNightForm';
import { EventRequirementsContext } from '../../context';
import {
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  ModalAction,
  ModalContent,
  GroupizeIcon,
} from '../../styles';

import { i18n } from '../../../../../src/custom/Internationalization';

function MeetingRoomsModal({ handleClose }) {
  const dataContext = useContext(EventRequirementsContext);
  const { formData, setFormData } = dataContext;

  const {
    meetingStartDate,
    meetingEndDate,
    meetingRoomNights,
    meetingStartTime,
    meetingEndTime,
    maxMeetingAttendees,
    setup,
    isMeetingRoomsCustomized,
  } = formData;

  const [localMeetingRoomNights, setLocalMeetingRoomNights] = useState(meetingRoomNights || []);
  const [nextDay, setNextDay] = useState(meetingEndDate);
  const startDate = meetingStartDate;

  const defaultModalData = {
    open: false,
    maxWidth: 'lg',
    showActionBtns: true,
    cancelBtnCopy: 'Cancel',
    confirmBtnCopy: 'Confirm',
  };

  const newRoomsData = {
    id: 'new',
    roomName: '',
    type: '',
    startTime: meetingStartTime || '7:00',
    endTime: meetingEndTime || '18:00',
    attendeesNum: maxMeetingAttendees || 0,
    setup,
  };

  const [showDateModal, setShowDateModal] = useState(false);
  const [newRoomData, setNewRoomData] = useState(newRoomsData);
  const [roomAddModalData, setRoomAddModalData] = useState({
    ...defaultModalData,
    title: i18n.t('event_request_form.actions.add_room'),
    confirmBtnCopy: i18n.t('event_request_form.actions.add_room'),
    handleCancel: () => setRoomAddModalData({ ...roomAddModalData, open: false }),
    handleConfirm: () => setRoomAddModalData({ ...roomAddModalData, open: false }),
  });

  const [confirmationModalData, setConfirmationModalData] = useState({
    open: false,
    title: i18n.t('actions.delete'),
    maxWidth: 'sm',
    showActionBtns: true,
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.delete'),
    handleCancel: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
    handleConfirm: () => setConfirmationModalData({ ...confirmationModalData, open: false }),
  });

  const showConfirmationModal = ({ onConfirm }) => {
    setConfirmationModalData({
      ...confirmationModalData,
      handleConfirm: onConfirm,
      open: true,
    });
  };

  const updateRoomNights = (startDate, endDate) => {
    const getRoomNightByDate = (date) => {
      const defaultValue = {
        date,
        rooms: [
          { ...newRoomsData, id: nanoid() },
        ],
      };

      if (localMeetingRoomNights && localMeetingRoomNights.length > 0) {
        const roomNight = localMeetingRoomNights.find((rn) => rn.date === date);
        return roomNight || defaultValue;
      } return defaultValue;
    };

    const getNumOfNights = (start, end) => {
      const momentStartDate = moment(start).utc();
      const momentEndDate = moment(end).utc();
      return momentEndDate.diff(momentStartDate, 'days');
    };

    const newMeetingRoomNights = [];
    const numOfNights = getNumOfNights(startDate, endDate);

    Object.keys(Array.from(new Array((numOfNights > 0) ? numOfNights : 1))).forEach((i) => {
      const currentNight = moment(startDate).add(i, 'd').format('YYYY-MM-DD');
      const roomNight = getRoomNightByDate(currentNight);
      newMeetingRoomNights.push(roomNight);
    });

    setLocalMeetingRoomNights(newMeetingRoomNights);
  };

  useEffect(() => {
    if (!isMeetingRoomsCustomized) {
      updateRoomNights(meetingStartDate, meetingEndDate);
    }
  }, [isMeetingRoomsCustomized, meetingStartDate, meetingEndDate]);

  const setRoomNight = (newMRValue, date) => {
    const { id: roomId, ...roomData } = newMRValue;
    let newLocalMeetingRoomNights = localMeetingRoomNights || [];

    if (localMeetingRoomNights && localMeetingRoomNights.length > 0) {
      const roomNight = localMeetingRoomNights.find((rn) => rn.date === date);

      if (roomNight) {
        const { rooms } = roomNight;
        let newRooms = rooms;
        if (roomId === 'new') {
          newRooms.push({ ...roomData, id: nanoid() });
        } else {
          newRooms = rooms.map((room) => (room.id === roomId
            ? { ...room, ...roomData }
            : { ...room }));
        }
        newLocalMeetingRoomNights = localMeetingRoomNights.map((rn) => (rn.date === roomNight.date
          ? { ...rn, rooms: newRooms }
          : { ...rn }));
      } else {
        newLocalMeetingRoomNights.push({ date, rooms: [{ ...roomData, id: 0 }] });
      }
    } else {
      newLocalMeetingRoomNights.push({ date, rooms: [{ ...roomData, id: 0 }] });
    }

    setLocalMeetingRoomNights(newLocalMeetingRoomNights);
  };

  const removeRoomNight = (id, date) => {
    let newLocalMeetingRoomNights = localMeetingRoomNights || [];

    if (localMeetingRoomNights && localMeetingRoomNights.length > 0) {
      const roomNight = localMeetingRoomNights.find((rn) => rn.date === date);

      if (roomNight) {
        const { rooms } = roomNight;
        const roomIndex = rooms.findIndex((i) => i.id === id);
        const updatedRooms = rooms;

        if (roomIndex > -1) {
          updatedRooms.splice(roomIndex, 1);
        }

        newLocalMeetingRoomNights = localMeetingRoomNights.map((rn) => (rn.date === date
          ? { ...rn, rooms: updatedRooms }
          : { ...rn }));
      }

      setLocalMeetingRoomNights(newLocalMeetingRoomNights);
    }
  };

  const duplicateRoomNight = (id, date) => {
    let newLocalMeetingRoomNights = localMeetingRoomNights || [];

    if (localMeetingRoomNights && localMeetingRoomNights.length > 0) {
      const roomNight = localMeetingRoomNights.find((rn) => rn.date === date);

      if (roomNight) {
        const { rooms } = roomNight;
        const room = rooms.find((i) => i.id === id);
        const updatedRooms = rooms;

        if (room) {
          updatedRooms.push({ ...room, id: nanoid() });
        }

        newLocalMeetingRoomNights = localMeetingRoomNights.map((rn) => (rn.date === date
          ? { ...rn, rooms: updatedRooms }
          : { ...rn }));
      }

      setLocalMeetingRoomNights(newLocalMeetingRoomNights);
    }
  };

  const handleSave = () => {
    setFormData({
      ...formData,
      meetingRoomNights: localMeetingRoomNights,
      isMeetingRoomsCustomized: true,
    });
    handleClose();
  };

  const showAddRoomModal = (night) => {
    setRoomAddModalData({
      ...roomAddModalData,
      open: true,
      currentNight: night,
    });
  };

  const removeDate = (date) => {
    const lmr = localMeetingRoomNights;
    const dateIndex = lmr.findIndex((mn) => mn.date === date);
    if (dateIndex > -1) {
      lmr.splice(dateIndex, 1);
    }

    setLocalMeetingRoomNights(lmr);
    setConfirmationModalData({ ...confirmationModalData, open: false });
  };

  const addNewDate = (newDate) => {
    if (moment(newDate).isValid()) {
      const newDateFormatted = moment.utc(newDate).format('YYYY-MM-DD');
      const newLocalMeetingRoomNights = localMeetingRoomNights || [];
      const roomNight = newLocalMeetingRoomNights.find((rn) => rn.date === newDateFormatted);
      if (!roomNight) {
        newLocalMeetingRoomNights.push({
          date: newDateFormatted,
          rooms: [
            { ...newRoomsData, id: nanoid() },
          ],
        });
      }
      setLocalMeetingRoomNights(newLocalMeetingRoomNights);
      const isAfterLatestDay = moment(newDate).add(1, 'd').isAfter(nextDay);
      if (isAfterLatestDay) {
        setNextDay(moment(newDate).add(1, 'd').format('YYYY-MM-DD'));
      }
    }
    setShowDateModal(false);
  };

  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalAction onClick={handleClose}>
          <FiXCircle />
        </ModalAction>

        <ModalTitle>
          {i18n.t('event_request_form.headings.customize_meeting_room_requirements')}
        </ModalTitle>

        <ModalAction right>
          <GroupizeBtn
            size="large"
            variant="contained"
            onClick={handleSave}
          >
            {i18n.t('actions.save')}
          </GroupizeBtn>
        </ModalAction>
      </ModalHeader>

      <ModalContent>
        <PageSectionTitle nomargin>
          <FaRegHandshake />
          {i18n.t('event_request_form.headings.meeting_room_requirements')}
        </PageSectionTitle>

        {localMeetingRoomNights.map(({ date, rooms }) => {
          const friendlyDate = moment(date).format('dddd, MMMM DD, YYYY');
          const currentNight = moment(date).format('YYYY-MM-DD');

          return (
            <Accordion
              key={date}
              config={{
                isOpen: true,
                showDeleteIcon: true,
                titleIcon: <FiChevronDown />,
                titleText: friendlyDate,
                deleteIcon: <FiTrash2 />,
                deleteAction: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showConfirmationModal({
                    onConfirm: () => removeDate(date),
                  });
                },
              }}
            >
              <FlexFlow grow column splitSpace="1em">
                {rooms.map((room) => (
                  <RoomNightForm
                    key={room.id}
                    hasActionBtns
                    roomData={room}
                    currentNight={currentNight}
                    setRoomNight={setRoomNight}
                    removeRoomNight={removeRoomNight}
                    duplicateRoomNight={duplicateRoomNight}
                    maxMeetingAttendees={maxMeetingAttendees}
                  />
                ))}

                <FlexFlow justify="flex-end">
                  <GroupizeBtn onClick={() => showAddRoomModal(currentNight)}>
                    <FlexFlow align="center" splitSpace="0.5em">
                      <GroupizeIcon wrapped>
                        <FiPlus />
                      </GroupizeIcon>
                      <span>
                        {i18n.t('event_request_form.actions.add_meeting_room')}
                      </span>
                    </FlexFlow>
                  </GroupizeBtn>
                </FlexFlow>
              </FlexFlow>
            </Accordion>
          );
        })}

        <FlexFlow margin="1em 0 0" justify="flex-end">
          <GroupizeBtn onClick={() => setShowDateModal(true)}>
            <FlexFlow align="center" splitSpace="0.5em">
              <GroupizeIcon wrapped>
                <FiPlus />
              </GroupizeIcon>
              <span>
                {i18n.t('event_request_form.actions.add_date')}
              </span>
            </FlexFlow>
          </GroupizeBtn>
        </FlexFlow>
      </ModalContent>

      <ConfirmationDialog
        {...roomAddModalData}
        handleConfirm={() => {
          setRoomNight(newRoomData, roomAddModalData.currentNight);
          setRoomAddModalData({ ...roomAddModalData, open: false });
          setNewRoomData(newRoomsData);
        }}
      >
        <RoomNightForm
          roomData={newRoomData}
          currentNight={roomAddModalData.currentNight}
          setRoomNight={(roomData) => setNewRoomData(roomData)}
          maxMeetingAttendees={maxMeetingAttendees}
        />
      </ConfirmationDialog>

      <ConfirmationDialog {...confirmationModalData}>
        {i18n.t('form_tips.remove_confirmation')}
      </ConfirmationDialog>

      <AddDateModal
        isOpen={showDateModal}
        onCancel={() => setShowDateModal(false)}
        onConfirm={(v) => addNewDate(v)}
        startDate={startDate}
        endDate={nextDay}
      />
    </ModalWrapper>
  );
}

MeetingRoomsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default MeetingRoomsModal;
